import {
  faCheck,
  faPenToSquare,
  faTrash,
  faUserPlus,
  faXmark,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  deleteRoleUser,
  getRoleUsers,
  getRoles,
  updateRoleUser,
} from "../redux/Actions/userAction";
import OverlayLoader from "../components/OverlayLoader";
import { impersonateUser } from "../redux/Actions/userAction";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const navigate = useNavigate();
  let isProcessing = false;
  const [validated, setValidated] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const roleUserData = useSelector((state) => state.roleUsers);
  const { loading, error, roleUsers, success: deleteSuccess } = roleUserData;
  const { success } = useSelector((state) => state.addRoleUser);
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [editModel, setEditModel] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [screenLoading, setScreenLoading] = useState(false);
  const { roles } = useSelector((state) => state.getRoles);
  const { success: updateSuccess } = useSelector(
    (state) => state.updateRoleUser
  );
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role: roles.length > 0 ? roles[0]._id : null,
  });

  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setDuplicateError(false);
  };
  const handleShow = () => setShow(true);

  const handleEditShow = () => setEditModel(true);
  const handleEditClose = () => {
    setEditModel(false);
    setValidated(false);
    setDuplicateError(false);
  };

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleAddUserButton = () => {
    if (roles && roles.length > 0) {
      setValidated(false);
      setFormData({
        name: "",
        email: "",
        password: "",
        role: null,
      });
      setShow(true);
    } else {
      alert("You must have atleast one defind role before adding users");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const existingUser = roleUsers.find(
        (user) => user.name === formData.name || user.email === formData.email
      );
      if (existingUser) {
        setDuplicateError(true);
      } else {
        dispatch(addUser(formData));
        handleClose();
      }
    }
    setValidated(true);
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      dispatch(updateRoleUser(userToEdit._id, formData));
      handleEditClose();
    }
    setValidated(true);
  };
  const handleEditUser = (user) => {
    setValidated(false);
    setEditModel(true);
    setUserToEdit(user);
    setFormData({
      name: user.name,
      email: user.email,
      role: user.roles._id,
    });
  };
  const handleDelete = () => {
    if (userToDelete) {
      dispatch(deleteRoleUser(userToDelete));
      handleCloseDelete();
    }
  };

  const handleImpersonate = (client) => {
    if (isProcessing) return;
    isProcessing = true;
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(false);
      dispatch(impersonateUser(client._id, client.email, navigate));
      isProcessing = false;
    }, 1000);
  };

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getRoleUsers());
  }, [dispatch, success, deleteSuccess, updateSuccess]);
  const styles = {
    shadow: {
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  };
  return (
    <>
      {screenLoading ? <OverlayLoader /> : null}
      {/* ---------------------------------------- DELETE MODAL --------------------------------- */}
      <Modal
        backdrop="static"
        centered
        show={showDelete}
        onHide={handleCloseDelete}
      >
        <Modal.Body className="text-center fw-semibold">
          Are you sure you want to remove this user permanently?
        </Modal.Body>
        <Row className="justify-content-center my-3">
          <Col sm="auto">
            <Button
              variant="secondary"
              className="mx-2"
              onClick={handleCloseDelete}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              className="mx-2 px-4"
              onClick={handleDelete}
            >
              Yes
            </Button>
          </Col>
        </Row>
      </Modal>
      {/* ---------------------------------------- EDIT USER MODAL -------------------------------- */}
      <Modal
        show={editModel}
        onHide={handleEditClose}
        backdrop="static"
        centered
      >
        <Modal.Header className="bg-primary text-light">
          <Row className="w-100">
            <Col xs="auto" className="fs-4">
              Edit User
            </Col>
            <Col md="auto" className="ms-auto p-0">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={handleEditClose}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleEditSubmit}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                pattern="[A-Za-z ]+"
                name="name"
                placeholder="Enter user name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                name="email"
                placeholder="Enter user email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter a valid Email*
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Assign a role</Form.Label>
              {roles.length > 0 &&
                roles.map((role, index) => (
                  <Form.Check
                    required
                    className="my-2"
                    key={index}
                    type="radio"
                    id={`role-radio-${index}`}
                    label={role.title}
                    name="role"
                    value={role._id}
                    checked={formData.role === role._id}
                    onChange={handleInputChange}
                  />
                ))}
              <Form.Control.Feedback type="invalid">
                This field is required*
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="mt-4 mb-3">
              <Col sm="auto" className="ms-auto">
                <Button
                  className="me-3"
                  variant="secondary"
                  onClick={handleEditClose}
                >
                  Cancel
                </Button>
                <Button variant="success" type="submit">
                  Save Changes
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ---------------------------------------- ADD NEW MODAL -------------------------------- */}
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header className="bg-primary text-light">
          <Row className="w-100 align-items-center">
            <Col xs="auto" className="fs-4">
              Add New User
            </Col>
            <Col md="auto" className="ms-auto p-0">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={handleClose}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                pattern="[A-Za-z ]+"
                name="name"
                placeholder="Enter user name"
                onChange={handleInputChange}
              />
              {duplicateError ? (
                <Form.Text className="text-danger">
                  User with this email or name already exist
                </Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                name="email"
                placeholder="Enter user email"
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                Enter a valid Email*
              </Form.Control.Feedback>
              {duplicateError ? (
                <Form.Text className="text-danger">
                  User with this email or name already exist
                </Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="password"
                name="password"
                placeholder="Create a password"
                onChange={handleInputChange}
              />
              <Form.Control.Feedback type="invalid">
                This field is required*
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Assign a role</Form.Label>
              {roles.length > 0 &&
                roles.map((role, index) => (
                  <Form.Check
                    required
                    className="my-2"
                    key={index}
                    type="radio"
                    id={`role-radio-${index}`}
                    label={role.title}
                    name="role"
                    value={role._id}
                    onChange={handleInputChange}
                    checked={formData.role === role._id}
                  />
                ))}
              <Form.Control.Feedback type="invalid">
                This field is required*
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="mt-4 mb-3">
              <Col sm="auto" className="ms-auto">
                <Button
                  className="me-3"
                  variant="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button variant="success" type="submit">
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Row className="p-3">
        {/* -------------------------------------- ADD NEW BUTTON --------------------------------- */}
        <Row className="mt-3">
          <Col sm="auto" className="ms-auto">
            <Button
              className="rounded-4 px-3 py-2"
              onClick={handleAddUserButton}
            >
              <FontAwesomeIcon icon={faUserPlus} className="me-2" /> Add new
            </Button>
          </Col>
        </Row>
        {/* --------------------------------------- USER LIST --------------------------------------- */}
        <Row>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message>{error}</Message>
          ) : (
            <Col>
              {roleUsers.length > 0 &&
                roleUsers.map((user, index) => (
                  <Card
                    key={index}
                    className="rounded-4 border-1 p-2 my-4"
                    // style={styles.shadow}
                  >
                    <Card.Body>
                      <Row>
                        <Col>
                          <Row className="align-items-center">
                            <Col xs="auto" className="p-0 mx-1">
                              <FontAwesomeIcon
                                icon={faCircleUser}
                                className="fs-2 text-primary"
                              />
                            </Col>
                            <Col sm="auto">
                              <h4 className="m-0">{user.name}</h4>
                              <h6 className="text-muted m-0">{user.email}</h6>
                            </Col>
                            <Col sm="auto" className="ms-auto">
                              <Button
                                className=" border-0"
                                variant="outline-primary"
                                onClick={() => {
                                  handleEditUser(user);
                                }}
                              >
                                <FontAwesomeIcon
                                  className="fs-5"
                                  icon={faPenToSquare}
                                />
                              </Button>
                              <Button
                                variant="outline-primary"
                                className="border-0"
                                onClick={() => {
                                  handleImpersonate(user);
                                }}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </Button>
                              <Button
                                className="mx-1 border-0"
                                variant="outline-danger"
                                onClick={() => {
                                  setUserToDelete(user._id);
                                  handleShowDelete();
                                }}
                              >
                                <FontAwesomeIcon
                                  className="fs-5"
                                  icon={faTrash}
                                />
                              </Button>
                            </Col>
                          </Row>
                          <hr className="my-2" />
                          <Row className="mt-3">
                            <Row>
                              <Col>
                                Role :
                                <div className="text-success mx-3 d-inline">
                                  {user.roles.title}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                Permissions :
                                <div className="mx-1 d-inline">
                                  {user.roles.permissions.viewAttendants ? (
                                    <Badge className="mx-2" pill bg="success">
                                      <FontAwesomeIcon
                                        className="me-1"
                                        icon={faCheck}
                                      />
                                      Attendants Dashboard
                                    </Badge>
                                  ) : (
                                    <></>
                                  )}
                                  {user.roles.permissions.viewValets ? (
                                    <Badge className="mx-2" pill bg="success">
                                      <FontAwesomeIcon
                                        className="me-1"
                                        icon={faCheck}
                                      />
                                      Valet Dashboard
                                    </Badge>
                                  ) : (
                                    <></>
                                  )}
                                  {user.roles.permissions.viewBellmans ? (
                                    <Badge className="mx-2" pill bg="success">
                                      <FontAwesomeIcon
                                        className="me-1"
                                        icon={faCheck}
                                      />
                                      Bell Desk Dashboard
                                    </Badge>
                                  ) : (
                                    <></>
                                  )}
                                  <Badge
                                    className="mx-2"
                                    pill
                                    bg={
                                      user.roles.permissions.read
                                        ? "success"
                                        : "secondary"
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="me-1"
                                      icon={faCheck}
                                    />
                                    Read
                                  </Badge>
                                  <Badge
                                    className="mx-2"
                                    pill
                                    bg={
                                      user.roles.permissions.download
                                        ? "success"
                                        : "secondary"
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="me-1"
                                      icon={faCheck}
                                    />
                                    Download Reports
                                  </Badge>
                                  <Badge
                                    className="mx-2"
                                    pill
                                    bg={
                                      user.roles.permissions.addMaid
                                        ? "success"
                                        : "secondary"
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="me-1"
                                      icon={faCheck}
                                    />
                                    Add Maid
                                  </Badge>
                                  <Badge
                                    className="mx-2"
                                    pill
                                    bg={
                                      user.roles.permissions.deleteMaid
                                        ? "success"
                                        : "secondary"
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="me-1"
                                      icon={faCheck}
                                    />
                                    Delete Maid
                                  </Badge>
                                </div>
                              </Col>
                            </Row>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ))}
            </Col>
          )}
        </Row>
      </Row>
    </>
  );
};

export default AddUser;
