import {
  CHATBOT_SESSIONS_GET_ALL_FAIL,
  CHATBOT_SESSIONS_GET_ALL_REQUEST,
  CHATBOT_SESSIONS_GET_ALL_SUCCESS,
} from "../Constants/chatbotSessionConstants";

export const chatbotSessionsReducer = (
  state = { sessions: [], groupedSessions: [] },
  action
) => {
  switch (action.type) {
    case CHATBOT_SESSIONS_GET_ALL_REQUEST:
      return { loading: true, sessions: [] };
    case CHATBOT_SESSIONS_GET_ALL_SUCCESS:
      return {
        loading: false,
        sessions: action.payload.sessions,
        groupedSessions: action.payload.groupedSessions,
      };
    case CHATBOT_SESSIONS_GET_ALL_FAIL:
      return { loading: false, error: action.payload, sessions: [] };
    default:
      return state;
  }
};
