import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { userLogin } from "../redux/Actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import logo from "../images/tipkit-logo-main.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const login = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = login;
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(userLogin(email, password));
  };
  useEffect(() => {
    if (userInfo) {
      const redirectPath =
        userInfo.isSuperAdmin == true || userInfo.isPartner == true
          ? "/super-admin"
          : userInfo.isAdmin == true && userInfo.isAdminX == false
          ? "/dashboard"
          : userInfo.isAdminX == true && userInfo.isAdmin == true
          ? "/dashboard/adminx"
          : userInfo.isAdmin == false && userInfo.isAdminX == false
          ? "/dashboard"
          : "/";
      navigation(redirectPath);
    }
  }, [userInfo, navigation]);
  const styles = {
    shadow: {
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  };
  return (
    <>
      <Row className="justify-content-center align-items-center vh-100 m-0 bg-light ">
        <Col
          md={4}
          className="pt-5 rounded-5 border border-1 bg-white"
          style={styles.shadow}
        >
          <Row className="justify-content-center mb-5">
            <Col xs="auto">
              <img src={logo} alt="tipkit" width="250" />
            </Col>
          </Row>
          <Row>
            <Col className="px-5">
              {error ? <Message variant="danger">{error}</Message> : null}
              {loading ? <Loader /> : null}
            </Col>
          </Row>
          <Form className=" px-5 " onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="fw-bold">Email</Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="fw-bold ">Password</Form.Label>
              {/* <Form.Control
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              /> */}
              <div className="position-relative">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  // style={{ paddingRight: "40px" }}
                />
                <span
                  className="position-absolute top-50 end-0 translate-middle-y me-2 text-muted"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </span>
              </div>
              <span
                className="text-muted fs-7 text-decoration-underline hover-effect"
                onClick={() => navigation("/reset-password")}
              >
                Forgot password?
              </span>
            </Form.Group>
            <Row className="justify-content-center px-3 mb-3 pt-4">
              <Button className="py-2" variant="primary" type="submit">
                Login
              </Button>
            </Row>
          </Form>
          <Row className="mt-5 pb-2">
            <Col className="text-center" style={{ fontSize: "13px" }}>
              <a className="text-decoration-none text-primary" href="/terms">
                Terms of use{" "}
              </a>
              and
              <a
                className="text-decoration-none text-primary"
                href="/privacyPolicy"
              >
                {" "}
                Privacy Policy
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Login;
