import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge } from "react-bootstrap";

const CellBadge = ({ label }) => {
  return (
    <Badge
      bg="primary"
      className="py-2"
      style={{ paddingLeft: "10px", paddingRight: "10px" }}
    >
      <FontAwesomeIcon icon={faUser} className="me-2" />
      {label}
    </Badge>
  );
};

export default CellBadge;
