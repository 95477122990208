import {
  faArrowTurnDown,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck, faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getMonthlyCommission,
  shiftMonthlyCommission,
} from "../redux/Actions/userAction";
import Loader from "./Loader";
import Message from "./Message";

const CommissionMonthlyReport = ({ partnerId }) => {
  const { loading, error, monthlyCommission } = useSelector(
    (state) => state.monthlyCommission
  );
  const {
    loading: shiftingLoading,
    error: shiftingError,
    shiftedSuccess,
  } = useSelector((state) => state.shiftCommission);

  const dispatch = useDispatch();
  const handleShift = (partnerId, monthNumber) => {
    dispatch(shiftMonthlyCommission(partnerId, monthNumber));
  };

  useEffect(() => {
    dispatch(getMonthlyCommission(partnerId));
  }, [shiftedSuccess]);

  const sortedMonthlyCommission = monthlyCommission.slice().sort((a, b) => {
    if (a.month === b.month) {
      return a.year - b.year;
    }
    return a.month - b.month;
  });

  return (
    <>
      <Row className="justify-content-center mb-2">
        <Col sm={3} className="fw-bold">
          Month
        </Col>
        <Col sm={3} className="fw-bold text-center">
          Total commission
        </Col>
        <Col xs={3} className="ms-auto fw-bold text-center">
          Status
        </Col>
        <Col xs={2} className="ms-auto fw-bold text-center">
          Shift
        </Col>
      </Row>
      <hr className="mb-1" />
      {loading ? (
        <Loader />
      ) : error || shiftingError ? (
        <Message>{error || shiftingError}</Message>
      ) : (
        sortedMonthlyCommission &&
        sortedMonthlyCommission.map((earnings, index) => (
          <div key={index}>
            <Row className="justify-content-center align-items-center p-2">
              <Col sm={3}>
                {earnings.month < 10 ? `0${earnings.month}` : earnings.month}/
                {earnings.year}
              </Col>
              <Col sm={3} className="text-center text-success">
                ${parseFloat(earnings.totalCommission).toFixed(2)}
              </Col>
              <Col xs={3} className="ms-auto text-center">
                {earnings.transfered === true ? (
                  <>
                    <span className="text-success">Transfered</span>
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className=" text-success ms-1"
                    />
                  </>
                ) : earnings.transfered === false &&
                  earnings.shifted === true ? (
                  <>
                    <span className="text-info">Shifted</span>
                    <FontAwesomeIcon
                      icon={faMoneyBillTransfer}
                      className=" text-info ms-1"
                    />
                  </>
                ) : (
                  <>
                    <span className="text-muted">Pending</span>
                    <FontAwesomeIcon
                      icon={faClock}
                      className="text-muted ms-1"
                    />
                  </>
                )}
              </Col>
              <Col sm={2} className="text-center text-success">
                <Button
                  className="border-0 rounded-2"
                  size="sm"
                  disabled={earnings.shifted || earnings.transfered}
                  variant={
                    earnings.shifted || earnings.transfered
                      ? "secondary"
                      : "info"
                  }
                  onClick={() => handleShift(partnerId, earnings.month)}
                >
                  {loading ? "..." : <FontAwesomeIcon icon={faShare} />}
                </Button>
              </Col>
            </Row>
            <hr className="my-1" />
          </div>
        ))
      )}
    </>
  );
};

export default CommissionMonthlyReport;
