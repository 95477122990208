export const calculateTotalSum = (transactions) => {
  const sum = transactions.reduce((total, transaction) => {
    return total + (transaction.amount || 0);
  }, 0);

  return parseFloat(sum.toFixed(2));
};

export const calculateTotalSubcharged = (transactions) => {
  const subchargedSum = transactions.reduce((total, transaction) => {
    const subchargedAmount =
      (transaction.amount || 0) - (transaction.tipAmount || 0);
    return total + subchargedAmount;
  }, 0);

  return parseFloat(subchargedSum.toFixed(2));
};

export const calculateTotalStripeFee = (transactions) => {
  const totalStripeFee = transactions.reduce((total, transaction) => {
    return total + (parseFloat(transaction.stripeFee) || 0);
  }, 0);

  return parseFloat(totalStripeFee.toFixed(2));
};

export const calculateTotalCommissionCharged = (transactions) => {
  const totalCommission = transactions.reduce((total, transaction) => {
    return total + (transaction.commissionCharged || 0);
  }, 0);

  return parseFloat(totalCommission.toFixed(2));
};

// Function to get monthly data
export const getMonthlyData = (transactions) => {
  if (!Array.isArray(transactions)) {
    return Array(12).fill(0); // Return a default array if transactions is undefined or not an array
  }

  const months = Array(12).fill(0); // Initialize array with 12 months, all set to 0
  transactions.forEach((transaction) => {
    const month = new Date(transaction.createdAt).getMonth(); // Extract month from the date
    months[month] += 1; // Increment the transaction count for the month
  });
  return months;
};

export const getWeeklyData = (transactions, targetMonth, targetYear) => {
  const weeklyData = {};
  transactions.forEach((transaction) => {
    const transactionDate = new Date(transaction.createdAt);
    if (
      transactionDate.getMonth() === targetMonth &&
      transactionDate.getFullYear() === targetYear
    ) {
      const dayOfMonth = transactionDate.getDate();
      const weekNumber = Math.ceil(dayOfMonth / 7);
      if (!weeklyData[weekNumber]) {
        weeklyData[weekNumber] = {};
      }
      if (!weeklyData[weekNumber][dayOfMonth]) {
        weeklyData[weekNumber][dayOfMonth] = 0;
      }
      weeklyData[weekNumber][dayOfMonth] += 1;
    }
  });

  // Generate a data array for Chart.js
  const weeklyCounts = [];
  const labels = Array.from({ length: 31 }, (_, i) => i + 1); // Days of the month (1-31)

  for (let week = 1; week <= 5; week++) {
    const weekData = labels.map((day) =>
      weeklyData[week] && weeklyData[week][day] ? weeklyData[week][day] : 0
    );
    weeklyCounts.push(weekData);
  }
  return {
    labels,
    datasets: weeklyCounts.map((data, index) => ({
      label: `Week ${index + 1}`,
      data,
      fill: false,
      borderColor: `rgba(75, 192, 192, ${0.5 + index * 0.1})`,
      tension: 0.1,
    })),
  };
};

export const calculateTeamTipStats = (transactions) => {
  const validTransactions = transactions.filter(
    (transaction) => !transaction.refundStatus
  );
  console.log("Length of valid transactions:", validTransactions.length);
  console.log("Length of transactions:", transactions.length);
  const teamTipTransactions = validTransactions.filter(
    (transaction) => transaction.connectAccount === "Team Payment"
  );
  const numberOfTeamTips = teamTipTransactions.length;
  const percentageOfTeamTips =
    validTransactions.length > 0
      ? Math.round((numberOfTeamTips / transactions.length) * 100)
      : 0;
  return {
    numberOfTeamTipTransactions: numberOfTeamTips,
    percentageOfTeamTips,
  };
};

export const calculateIndividualTipStats = (transactions) => {
  const validTransactions = transactions.filter(
    (transaction) => !transaction.refundStatus
  );

  const individualTipTransactions = validTransactions.filter(
    (transaction) => transaction.connectAccount !== "Team Payment"
  );

  const numberOfIndividualTips = individualTipTransactions.length;
  const percentageOfIndividualTips =
    validTransactions.length > 0
      ? Math.round((numberOfIndividualTips / transactions.length) * 100)
      : 0;

  return {
    numberOfIndividualTipTransactions: numberOfIndividualTips,
    percentageOfIndividualTips,
  };
};

export const calculateRefundedTransactionStats = (transactions) => {
  // Exclude refunded transactions from the total count
  const validTransactions = transactions.filter(
    (transaction) => !transaction.refundStatus
  );

  const refundedTransactions = transactions.filter(
    (transaction) => transaction.refundStatus === true
  );

  const numberOfRefundedTransactions = refundedTransactions.length;
  const percentageOfRefundedTransactions =
    transactions.length > 0
      ? Math.round((numberOfRefundedTransactions / transactions.length) * 100)
      : 0;

  return {
    numberOfRefundedTransactions,
    percentageOfRefundedTransactions,
  };
};

export const calculateMaidStats = (staffMembers) => {
  const maidMembers = staffMembers.filter((staff) => staff.type === "maid");
  const totalMaids = maidMembers.length;
  const totalStaff = staffMembers.length;

  const activeMaids = maidMembers.filter(
    (staff) => staff.chargesStatus === true
  ).length;
  const inactiveMaids = totalMaids - activeMaids;

  const percentageOfMaids =
    totalStaff > 0 ? Math.floor((totalMaids / totalStaff) * 100) : 0;

  return {
    totalMaids: totalMaids,
    percentageOfMaids: percentageOfMaids,
    activeMaids: activeMaids,
    inactiveMaids: inactiveMaids,
  };
};

export const calculateValetStats = (staffMembers) => {
  const valetMembers = staffMembers.filter((staff) => staff.type === "valet");
  const totalValets = valetMembers.length;
  const totalStaff = staffMembers.length;

  const activeValets = valetMembers.filter(
    (staff) => staff.chargesStatus === true
  ).length;
  const inactiveValets = totalValets - activeValets;

  const percentageOfValets =
    totalStaff > 0 ? Math.floor((totalValets / totalStaff) * 100) : 0;

  return {
    totalValets: totalValets,
    percentageOfValets: percentageOfValets,
    activeValets: activeValets,
    inactiveValets: inactiveValets,
  };
};

export const calculateBellmanStats = (staffMembers) => {
  const bellmanMembers = staffMembers.filter(
    (staff) => staff.type === "bellman"
  );
  const totalBellmen = bellmanMembers.length;
  const totalStaff = staffMembers.length;

  const activeBellmen = bellmanMembers.filter(
    (staff) => staff.chargesStatus === true
  ).length;
  const inactiveBellmen = totalBellmen - activeBellmen;

  const percentageOfBellmen =
    totalStaff > 0 ? Math.floor((totalBellmen / totalStaff) * 100) : 0;

  return {
    totalBellmen: totalBellmen,
    percentageOfBellmen: percentageOfBellmen,
    activeBellmen: activeBellmen,
    inactiveBellmen: inactiveBellmen,
  };
};

// Function to filter verified team tips
export const getVerifiedTeamTips = (valetTeams) => {
  return valetTeams.filter((team) => team.verified === true).length;
};

// Function to filter unverified team tips
export const getUnverifiedTeamTips = (valetTeams) => {
  return valetTeams.filter((team) => team.verified === false).length;
};
