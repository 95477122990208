export const PAYMENT_GET_ALL_REQUEST = "PAYMENT_GET_ALL_REQUEST";
export const PAYMENT_GET_ALL_SUCCESS = "PAYMENT_GET_ALL_SUCCESS";
export const PAYMENT_GET_ALL_FAIL = "PAYMENT_GET_ALL_FAIL";

export const FAILED_PAYMENT_GET_ALL_REQUEST = "FAILED_PAYMENT_GET_ALL_REQUEST";
export const FAILED_PAYMENT_GET_ALL_SUCCESS = "FAILED_PAYMENT_GET_ALL_SUCCESS";
export const FAILED_PAYMENT_GET_ALL_FAIL = "FAILED_PAYMENT_GET_ALL_FAIL";

export const GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL";

export const GET_PAYMENT_REPORTS_REQUEST = "GET_PAYMENT_REPORTS_REQUEST";
export const GET_ALL_PAYMENT_REPORTS = "GET_ALL_PAYMENT_REPORTS";
export const GET_PAYMENT_REPORTS_SUCCESS = "GET_PAYMENT_REPORTS_SUCCESS";
export const GET_PAYMENT_REPORTS_FAIL = "GET_PAYMENT_REPORTS_FAIL";

export const GET_TEAM_PAYOUTS_REQUEST = "GET_TEAM_PAYOUTS_REQUEST";
export const GET_TEAM_PAYOUTS_SUCCESS = "GET_TEAM_PAYOUTS_SUCCESS";
export const GET_TEAM_PAYOUTS_FAIL = "GET_TEAM_PAYOUTS_FAIL";

export const GET_CLIENT_PAYMENTS_REQUEST = "GET_CLIENT_PAYMENTS_REQUEST";
export const GET_CLIENT_PAYMENTS_SUCCESS = "GET_CLIENT_PAYMENTS_SUCCESS";
export const GET_CLIENT_PAYMENTS_FAIL = "GET_CLIENT_PAYMENTS_FAIL";

export const PAYMENTS_BY_DATE_REQUEST = "PAYMENTS_BY_DATE_REQUEST";
export const PAYMENTS_BY_DATE_SUCCESS = "PAYMENTS_BY_DATE_SUCCESS";
export const PAYMENTS_BY_DATE_FAIL = "PAYMENTS_BY_DATE_FAIL";

export const GET_ADMINX_PAYMENTS_REQUEST = "GET_ADMINX_PAYMENTS_REQUEST";
export const GET_ADMINX_PAYMENTS_SUCCESS = "GET_ADMINX_PAYMENTS_SUCCESS";
export const GET_ADMINX_PAYMENTS_FAIL = "GET_ADMINX_PAYMENTS_FAIL";

export const ADMINX_PAYMENTS_BY_DATE_REQUEST =
  "ADMINX_PAYMENTS_BY_DATE_REQUEST";
export const ADMINX_PAYMENTS_BY_DATE_SUCCESS =
  "ADMINX_PAYMENTS_BY_DATE_SUCCESS";
export const ADMINX_PAYMENTS_BY_DATE_FAIL = "ADMINX_PAYMENTS_BY_DATE_FAIL";
