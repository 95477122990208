import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Row,
  FormControl,
  Badge,
  Dropdown,
} from "react-bootstrap";
import { Circle } from "rc-progress";
import { Doughnut, Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { getAllMaids, getValetTeams } from "../redux/Actions/maidAction";
import { getClientPayments } from "../redux/Actions/paymentAction";
import {
  calculateBellmanStats,
  calculateIndividualTipStats,
  calculateMaidStats,
  calculateRefundedTransactionStats,
  calculateTeamTipStats,
  calculateTotalSum,
  calculateValetStats,
  getMonthlyData,
  getUnverifiedTeamTips,
  getVerifiedTeamTips,
  getWeeklyData,
} from "../controllers/stats";
import Loader from "../components/Loader";
import StaffStats from "../statsComponents/StaffStats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCalendarDay,
  faClockRotateLeft,
  faMagnifyingGlass,
  faRotateLeft,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { DatePicker, Select } from "antd";

Chart.register(...registerables);
const ClientStats = () => {
  const { RangePicker } = DatePicker;
  const [showDropdown, setShowDropdown] = useState(false);
  const [staff, setStaff] = useState([]);
  const [valetTeams, setValetTeams] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [fliterApplied, setFilterApplied] = useState(false);
  const [monthlyData, setMonthlyData] = useState({});
  const [weeklyData, setWeeklyData] = useState({});
  const [doughnutData, setDoughnutData] = useState(null);
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [monthSelected, setMonthSelected] = useState({
    name: "",
    selection: false,
  });
  const [permissions, setPermissions] = useState({
    isAdmin: false,
    viewAttendants: false,
    viewValets: false,
    viewBellmans: false,
  });
  const { userInfo } = useSelector((state) => state.userLogin);
  const { user } = useSelector((state) => state.userDetails);
  const hotelId = userInfo.isAdmin == true ? userInfo._id : userInfo.hotel;
  const model = user?.model;
  const {
    maids: staffData,
    loading: staffLoading,
    error: staffError,
  } = useSelector((state) => state.maidsList);
  const {
    valetTeams: valetTeamsData,
    loading: valetTeamsLoading,
    error: valetTeamsError,
  } = useSelector((state) => state.valetTeams);
  const { payments: allPayments, loading: transactionsLoading } = useSelector(
    (state) => state.clientPayments
  );

  const dispatch = useDispatch();

  const handleDateRangeChange = (dates) => {
    if (dates === null || dates[0] === null || dates[1] === null) {
      setDateRange([null, null]);
    } else {
      setDateRange(dates);
    }
  };
  const handlePreviousMonthRange = () => {
    if (
      dateRange[0] != null &&
      dateRange[1] != null &&
      monthSelected.selection == false
    ) {
      return;
    }
    setMonthSelected((prevState) => ({
      ...prevState,
      selection: !prevState.selection,
    }));

    if (!monthSelected.selection) {
      const now = new Date();
      const firstDayOfPreviousMonth = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        1
      );
      const lastDayOfPreviousMonth = new Date(
        now.getFullYear(),
        now.getMonth(),
        0
      );
      const monthName = firstDayOfPreviousMonth.toLocaleString("default", {
        month: "long",
      });
      setDateRange([firstDayOfPreviousMonth, lastDayOfPreviousMonth]);
      setMonthSelected((prevState) => ({
        ...prevState,
        name: monthName,
      }));
    } else {
      setDateRange([null, null]);
      setMonthSelected((prevState) => ({
        ...prevState,
        name: null,
      }));
    }
  };

  useEffect(() => {
    dispatch(getClientPayments(hotelId, 1, "all"));
    dispatch(getValetTeams(hotelId));
    dispatch(getAllMaids(hotelId));
  }, []);

  useEffect(() => {
    if (staffData?.length > 0) {
      setStaff(staffData);
    }
    if (valetTeamsData?.length > 0) {
      setValetTeams(valetTeamsData);
    }
  }, [staffData, valetTeamsData]);

  useEffect(() => {
    if (allPayments && allPayments.length > 0) {
      const monthlyChartData = {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Transactions",
            data: getMonthlyData(
              fliterApplied ? filteredTransactions : allPayments
            ),
            backgroundColor: "rgba(75,192,192,0.4)", // Teal
            borderColor: "rgba(75,192,192,1)", // Teal
            borderWidth: 2,
            fill: true,
          },
        ],
      };
      setMonthlyData(monthlyChartData);

      // Calculating the weekly data
      let targetMonth;
      let targetYear;

      const [start, end] = dateRange;
      // const endDate = new Date(end);
      if (start && end) {
        const startDate = new Date(start);
        targetMonth = startDate.getMonth();
        targetYear = startDate.getFullYear();
      } else {
        targetMonth = new Date().getMonth();
        targetYear = new Date().getFullYear();
      }
      const weeklyDataCounts = getWeeklyData(
        fliterApplied ? filteredTransactions : allPayments,
        targetMonth,
        targetYear
      );
      const weeklyChartData = {
        labels: Array.from({ length: 31 }, (_, i) => i + 1),
        datasets: [
          {
            label: "Week 1",
            data: weeklyDataCounts.datasets[0].data,
            borderColor: "rgba(75, 192, 192, 1)", // Teal
            backgroundColor: "rgba(75, 192, 192, 0.2)", // Teal (transparent)
            borderWidth: 2,
            fill: true,
          },
          {
            label: "Week 2",
            data: weeklyDataCounts.datasets[1].data,
            borderColor: "rgba(153, 102, 255, 1)", // Purple
            backgroundColor: "rgba(153, 102, 255, 0.2)", // Purple (transparent)
            borderWidth: 2,
            fill: true,
          },
          {
            label: "Week 3",
            data: weeklyDataCounts.datasets[2].data,
            borderColor: "rgba(255, 159, 64, 1)", // Orange
            backgroundColor: "rgba(255, 159, 64, 0.2)", // Orange (transparent)
            borderWidth: 2,
            fill: true,
          },
          {
            label: "Week 4",
            data: weeklyDataCounts.datasets[3].data,
            borderColor: "rgba(255, 99, 132, 1)", // Red
            backgroundColor: "rgba(255, 99, 132, 0.2)", // Red (transparent)
            borderWidth: 2,
            fill: true,
          },
        ],
      };

      setWeeklyData(weeklyChartData);
    }
    // Calculating the doughnut chart data for valet team tips
    if (valetTeams && valetTeams.length > 0) {
      const verifiedTeamTipsCount = getVerifiedTeamTips(valetTeams);
      const unverifiedTeamTipsCount = getUnverifiedTeamTips(valetTeams);

      const doughnutChartData = {
        labels: ["Verified Tips", "Unverified Tips"],
        datasets: [
          {
            data: [verifiedTeamTipsCount, unverifiedTeamTipsCount],
            backgroundColor: ["#36A2EB", "#FF6384"],
            hoverBackgroundColor: ["#36A2EB", "#FF6384"],
          },
        ],
      };
      setDoughnutData(doughnutChartData);
    }
  }, [fliterApplied, filteredTransactions, valetTeams, allPayments]);

  useEffect(() => {
    const updatedPermissions = {
      ...permissions,
      isAdmin: user.isAdmin,
      viewAttendants: user.isAdmin
        ? user.services.maids
        : user.roles?.permissions?.viewAttendants || false,
      viewValets: user.isAdmin
        ? user?.services?.valets
        : user?.roles?.permissions?.viewValets || false,
      viewBellmans: user.isAdmin
        ? user?.services?.bellmen
        : user?.roles?.permissions?.viewBellmans || false,
    };
    setPermissions((prevPermissions) => {
      return JSON.stringify(prevPermissions) !==
        JSON.stringify(updatedPermissions)
        ? updatedPermissions
        : prevPermissions;
    });
    // setPermissions(updatedPermissions);
  }, [user]);

  useEffect(() => {
    setFilterApplied(false);
    let filteredTransactions = [...allPayments];
    let filteredStaff = [...staffData];
    let filteredValetTeams = [...valetTeamsData];

    // Filter by date range if both startDate and endDate are provided
    const [start, end] = dateRange;
    if (start && end) {
      setFilterApplied(true);
      console.log("Filtering by start & end date");
      const startDate = new Date(start);
      const endDate = new Date(end);

      filteredTransactions = filteredTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        return transactionDate >= startDate && transactionDate <= endDate;
      });
      filteredStaff = filteredStaff.filter((user) => {
        const userCreatedAt = new Date(user.createdAt);
        return userCreatedAt >= startDate && userCreatedAt <= endDate;
      });
      filteredValetTeams = filteredValetTeams.filter((team) => {
        const teamCreatedAt = new Date(team.createdAt);
        return teamCreatedAt >= startDate && teamCreatedAt <= endDate;
      });
    }

    setFilteredTransactions(filteredTransactions);
    setStaff(filteredStaff);
    setValetTeams(filteredValetTeams);
  }, [dateRange]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  return (
    <>
      <Row className="m-0 py-4">
        <Col>
          {/* ------------------------------------------------ FILTERS ---------------------------------------------- */}
          <Row className="align-items-center justify-content-center mb-5 p-2 rounded-2 bg-light">
            <Col xs="auto" className="">
              <h3 className="mb-0 text-primary fs-5 ">Perfomance Overview</h3>
              {monthSelected.selection && (
                <span className="text-muted fs-7">
                  For {monthSelected.name}
                </span>
              )}
            </Col>
            <Col xs="auto" className="ms-auto p-0">
              <RangePicker
                className="rounded-1 py-1 border-secondary w-100 border-0"
                onChange={handleDateRangeChange}
              />
            </Col>
            {/* <Col
              xs="auto"
              // className="bg-white py-1 mx-1 rounded-2"
              style={{ cursor: "pointer" }}
            >
              <Dropdown
                show={showDropdown}
                onToggle={() => setShowDropdown(!showDropdown)}
              >
                <div onClick={() => setShowDropdown(!showDropdown)}>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    size="sm"
                    className="text-muted"
                  />
                </div>
                {showDropdown && (
                  <Dropdown.Menu className="mt-3 border-0">
                    <DatePicker
                      picker="month"
                      placeholder=""
                      // onChange={handleMonthChange}
                    />
                  </Dropdown.Menu>
                )}
              </Dropdown>
            </Col> */}
            <Col xs="auto">
              <Badge
                bg={monthSelected.selection ? "success " : "white"}
                className={`${
                  monthSelected.selection ? "text-white" : "text-muted"
                } py-2 mx-1 fw-semibold`}
                style={{ cursor: "pointer" }}
                onClick={handlePreviousMonthRange}
              >
                {monthSelected.selection ? (
                  <FontAwesomeIcon icon={faXmarkCircle} className="me-1" />
                ) : (
                  <FontAwesomeIcon
                    icon={faClockRotateLeft}
                    size="sm"
                    className="me-1"
                  />
                )}
                Last month
              </Badge>
            </Col>
          </Row>
          {/* ===================================== TRANSACTION RECORDS =============================== */}
          {transactionsLoading ? (
            <Row>
              <Loader />
              <h6 className="text-center my-1 fw-normal">
                Loading Transaction Data
              </h6>
            </Row>
          ) : (
            <Row className="border border-0 px-5 my-4">
              <Col xs={4}>
                <h5 className=" fw-normal">Total Transactions</h5>
                <h2 className="fw-normal text-primary ms-5 ">
                  {(fliterApplied ? filteredTransactions : allPayments).length}
                </h2>
              </Col>
              <Col xs={4}>
                <h5 className=" fw-normal">Total Money Processed</h5>
                <h2 className="fw-normal text-success ms-5 ">
                  $
                  {calculateTotalSum(
                    fliterApplied ? filteredTransactions : allPayments
                  )}
                </h2>
              </Col>
              <Col xs={4} className="ms-auto">
                <Row>
                  {/* ------------------------------------------ INDIVIDUAL TIPS RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={
                          calculateIndividualTipStats(
                            fliterApplied ? filteredTransactions : allPayments
                          ).percentageOfIndividualTips
                        }
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#06BF9C"}
                      />
                      <div className="circle-text">
                        <span className="text-success">
                          {
                            calculateIndividualTipStats(
                              fliterApplied ? filteredTransactions : allPayments
                            ).percentageOfIndividualTips
                          }
                          %
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-success text-center">
                        Direct tips {""}
                        {
                          calculateIndividualTipStats(
                            fliterApplied ? filteredTransactions : allPayments
                          ).numberOfIndividualTipTransactions
                        }
                      </span>
                    </Row>
                  </Col>
                  {/* ------------------------------------------ VALE POOL TIPS RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={
                          calculateTeamTipStats(
                            fliterApplied ? filteredTransactions : allPayments
                          ).percentageOfTeamTips
                        }
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#06BF9C"}
                      />
                      <div className="circle-text">
                        <span className="text-success">
                          {
                            calculateTeamTipStats(
                              fliterApplied ? filteredTransactions : allPayments
                            ).percentageOfTeamTips
                          }
                          %
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-success text-center">
                        Pool tips {""}
                        {
                          calculateTeamTipStats(
                            fliterApplied ? filteredTransactions : allPayments
                          ).numberOfTeamTipTransactions
                        }
                      </span>
                    </Row>
                  </Col>
                  {/* ------------------------------------------ REFUNDED RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={
                          calculateRefundedTransactionStats(
                            fliterApplied ? filteredTransactions : allPayments
                          ).percentageOfRefundedTransactions
                        }
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#3498db"}
                      />
                      <div className="circle-text">
                        <span className="text-info">
                          {
                            calculateRefundedTransactionStats(
                              fliterApplied ? filteredTransactions : allPayments
                            ).percentageOfRefundedTransactions
                          }
                          %
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-info text-center">
                        Refunded {""}
                        {
                          calculateRefundedTransactionStats(
                            fliterApplied ? filteredTransactions : allPayments
                          ).numberOfRefundedTransactions
                        }
                      </span>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {/* ===================================== MONTHLY & WEELY GRAPHS ============================ */}
          <Row className="my-5 pt-4">
            <Col xs={6}>
              <h5 className="text-center mb-3">Monthly Tipping Activity</h5>
              {monthlyData?.datasets?.length > 0 && (
                <Line data={monthlyData} options={options} />
              )}
            </Col>
            <Col xs={6}>
              <h5 className="text-center mb-3">
                Weekly Tipping Activity{" "}
                <span className="fs-7 text-muted">(Current Month)</span>
              </h5>
              {weeklyData?.datasets?.length > 0 && (
                <Line data={weeklyData} options={options} />
              )}
            </Col>
          </Row>
          {/* ======================================== STAFF RECORDS ================================== */}
          {staffLoading ? (
            <Row>
              <Loader />
              <h6 className="text-center my-1 fw-normal">Loading Staff Data</h6>
            </Row>
          ) : (
            <Row className="border border-0 p-3 pt-5">
              <Col xs={5}>
                <h5 className=" fw-normal text-center">Total Staff</h5>
                <h2 className="fw-normal text-primary text-center">
                  {staff.length}
                </h2>
              </Col>
              <Col xs={4} className="ms-auto">
                <Row className="justify-content-center">
                  {/* ------------------------------------------ ATTENDANTS RATIO ------------------------------------ */}
                  {permissions.viewAttendants && (
                    <Col xs={4}>
                      <Row className="position-relative circle-container mx-auto">
                        <Circle
                          percent={calculateMaidStats(staff).percentageOfMaids}
                          strokeWidth={10}
                          trailWidth={8}
                          strokeColor={"#004172"}
                        />
                        <div className="circle-text">
                          <span className="text-primary">
                            {calculateMaidStats(staff).percentageOfMaids}%
                          </span>
                        </div>
                      </Row>
                      <Row>
                        <span className="text-primary text-center p-0">
                          {`Attendants ${calculateMaidStats(staff).totalMaids}`}
                        </span>
                      </Row>
                    </Col>
                  )}
                  {/* ------------------------------------------ VALET RATIO ------------------------------------ */}
                  {permissions.viewValets && (
                    <Col xs={4}>
                      <Row className="position-relative circle-container mx-auto">
                        <Circle
                          percent={
                            calculateValetStats(staff).percentageOfValets
                          }
                          strokeWidth={10}
                          trailWidth={8}
                          strokeColor={"#004172"}
                        />
                        <div className="circle-text">
                          <span className="text-primary">
                            {calculateValetStats(staff).percentageOfValets}%
                          </span>
                        </div>
                      </Row>
                      <Row>
                        <span className="text-primary text-center">
                          {`Valets ${calculateValetStats(staff).totalValets}`}
                        </span>
                      </Row>
                    </Col>
                  )}
                  {/* ------------------------------------------ BELLMEN RATIO ------------------------------------ */}
                  {permissions.viewBellmans && (
                    <Col xs={4}>
                      <Row className="position-relative circle-container mx-auto">
                        <Circle
                          percent={
                            calculateBellmanStats(staff).percentageOfBellmen
                          }
                          strokeWidth={10}
                          trailWidth={8}
                          strokeColor={"#004172"}
                        />
                        <div className="circle-text">
                          <span className="text-primary">
                            {calculateBellmanStats(staff).percentageOfBellmen}%
                          </span>
                        </div>
                      </Row>
                      <Row>
                        <span className="text-primary text-center">
                          {`Bell staff ${
                            calculateBellmanStats(staff).totalBellmen
                          }`}
                        </span>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          )}
          {/* ======================================== POOL TIPS RATIO ================================== */}
          {!model?.b2b && (
            <Row className="pt-5 justify-content-center align-items-center">
              <Col xs={permissions.viewValets ? 7 : 12}>
                {permissions.viewAttendants && (
                  <StaffStats
                    title={"Attendants"}
                    active={calculateMaidStats(staff).activeMaids}
                    inActive={calculateMaidStats(staff).inactiveMaids}
                  />
                )}
                {permissions.viewValets && (
                  <StaffStats
                    title={"Valets"}
                    active={calculateValetStats(staff).activeValets}
                    inActive={calculateValetStats(staff).inactiveValets}
                  />
                )}
                {permissions.viewBellmans && (
                  <StaffStats
                    title={"Bell Staff"}
                    active={calculateBellmanStats(staff).activeBellmen}
                    inActive={calculateBellmanStats(staff).inactiveBellmen}
                  />
                )}
              </Col>
              {permissions.viewValets && (
                <Col xs={4}>
                  {valetTeamsLoading ? (
                    <Row>
                      <Loader />
                      <h6 className="text-center my-1 fw-normal">
                        Loading Teams Data
                      </h6>
                    </Row>
                  ) : (
                    <div>
                      <h5 className="text-center">Valet Pool Tips</h5>
                      {doughnutData && <Doughnut data={doughnutData} />}
                    </div>
                  )}
                </Col>
              )}
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ClientStats;
