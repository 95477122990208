import React from "react";
import { Col, Row } from "react-bootstrap";

const StaffStats = ({ title, total, active, inActive }) => {
  const initial = title.charAt(0).toUpperCase();
  return (
    <Row>
      <hr className="mt-3" />
      <Col xs="auto" className="me-4">
        <Row className="justify-content-center align-items-center h-100">
          <Col
            className="border border-1 rounded-circle d-flex align-items-center justify-content-center fs-3 bg-light"
            xs="auto"
          >
            <span className="fw-bold text-muted p-2">{initial}</span>
          </Col>
        </Row>
      </Col>
      <Col>
        <h4>{title}</h4>
        <Row className="justify-content-between p-0">
          <Col xs="auto">Active</Col>
          <Col xs="auto" className="text-success">
            {active}
          </Col>
        </Row>
        <Row className="justify-content-between p-0">
          <Col xs="auto">Inactive</Col>
          <Col xs="auto" className="text-warning">
            {inActive}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default StaffStats;
