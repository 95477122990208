import React, { useState } from "react";
import { Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import ValetList from "./ValetList";
import Reports from "./Reports";
import ValetTeam from "./ValetTeam";
import VerifiedTeamTips from "./VerifiedTeamTips";
import {
  faCircleCheck,
  faClipboard,
  faHourglass,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Valets = ({ toAddMaid, toDeleteMaid, toDownload, isAdmin, canRead }) => {
  const [activeScreen, setActiveScreen] = useState("valets");

  return (
    <>
      <Row className="my-4 justify-content-center">
        <Col xs={3}>
          <Button
            className="py-2 px-3 rounded-4 w-100"
            variant={
              activeScreen === "valets" ? "primary" : "outline-secondary"
            }
            onClick={() => setActiveScreen("valets")}
          >
            <FontAwesomeIcon icon={faUsers} className="me-2" />
            Valets
          </Button>
        </Col>
        <Col xs={4} className="border border-1 p-0 rounded-4">
          <Button
            className="py-2 px-3 rounded-4 w-50 border-0"
            variant={activeScreen === "team" ? "primary" : "outline-secondary"}
            onClick={() => setActiveScreen("team")}
          >
            <FontAwesomeIcon icon={faHourglass} className="me-2" />
            Team Tips
          </Button>
          <Button
            className="py-2 px-3 rounded-4 w-50 border-0"
            variant={
              activeScreen === "verifiedTips" ? "primary" : "outline-secondary"
            }
            onClick={() => setActiveScreen("verifiedTips")}
          >
            <FontAwesomeIcon icon={faCircleCheck} className="me-2" />
            Verified Team Tips
          </Button>
        </Col>
        <Col xs={3}>
          <Button
            className="py-2 px-3 rounded-4 w-100"
            variant={
              activeScreen === "reports" ? "primary" : "outline-secondary"
            }
            onClick={() => setActiveScreen("reports")}
          >
            <FontAwesomeIcon icon={faClipboard} className="me-2" />
            Reports
          </Button>
        </Col>
      </Row>
      <Row className="">
        <Col>
          {activeScreen === "valets" && (
            <ValetList toAddMaid={toAddMaid} toDeleteMaid={toDeleteMaid} />
          )}
          {activeScreen === "team" && <ValetTeam canRead={canRead} />}
          {activeScreen === "verifiedTips" && (
            <VerifiedTeamTips canRead={canRead} />
          )}
          {activeScreen === "reports" && (
            <Reports toDownload={toDownload} isAdmin={isAdmin} type={"valet"} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Valets;
