import React from "react";
import { Spinner } from "react-bootstrap";
import { Atom } from "react-loading-indicators";

const OverlayLoader = () => {
  return (
    <div className="overlay">
      <div className="loading-icon">
        <Atom color="#004172" size="medium" text="" textColor="" />
      </div>
    </div>
  );
};

export default OverlayLoader;
