import React, { useEffect, useRef } from "react";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Col, Row, Card, Button } from "react-bootstrap";
import tipkitLogo from "../images/tipkit-logo.png";

const ReceiptDownloadPage = () => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const searchParams = new URLSearchParams(window.location.search);
  const transactionDataString = searchParams.get("data");
  const transactionData = JSON.parse(decodeURIComponent(transactionDataString));
  const cardRef = useRef(null);

  const download = () => {
    const cardElement = cardRef.current;
    const options = {
      margin: 10,
      filename: "receipt.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", orientation: "portrait" },
    };
    html2pdf(cardElement, options);
  };
  useEffect(() => {
    download();
  });
  return (
    <>
      {transactionData && (
        <Row className="justify-content-center h-100 align-items-center m-0">
          <Col xs="auto">
            {transactionData && (
              <Card
                className="rounded-4 px-2 pt-3"
                ref={cardRef}
                style={{
                  width: "23rem",
                }}
              >
                <Card.Body>
                  <Row className="justify-content-center px-2">
                    <Col xs="auto" className="text-center">
                      <Card.Img
                        variant="top"
                        src={tipkitLogo}
                        className="w-75"
                      />
                    </Col>
                    <hr className="mt-3 text-secondary" />
                  </Row>
                  <Row className="my-1">
                    <Card.Title className="text-center text-primary fw-bold">
                      {transactionData.clientName}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted text-center">
                      {transactionData.clientLocation}
                    </Card.Subtitle>
                  </Row>
                  <Row>
                    <Row className="my-1">
                      <Col xs={6} className="fw-bold">
                        Date :
                      </Col>
                      <Col xs={6} className="text-muted">
                        {new Date(transactionData.createdAt).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col xs={6} className="fw-bold">
                        Payment ID :
                      </Col>
                      <Col xs={6} className="fst-italic text-muted">
                        {transactionData.paymentIntentId}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="fw-bold">
                        Recipient ID :
                      </Col>
                      <Col xs={6} className="fst-italic text-muted">
                        {transactionData.connectAccount}
                      </Col>
                    </Row>
                  </Row>
                  <Row className="mt-4">
                    <hr />
                    <Row className="my-1">
                      <Col xs={6} className="fw-bold">
                        Tip :
                      </Col>
                      <Col xs={6}>${transactionData.tipAmount}</Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="fw-bold">
                        Processing Fee :
                      </Col>
                      <Col xs={6}>${transactionData.processingFee}</Col>
                    </Row>
                    <hr className="mt-3 mb-2" />
                    <Row>
                      <Col xs={6} className="fw-bold">
                        Amount Paid :
                      </Col>
                      <Col xs={6} className="text-success">
                        ${transactionData.amount}
                      </Col>
                    </Row>
                  </Row>
                </Card.Body>
                <Row className="mt-3 mb-2">
                  <Col className="fs-7 text-muted text-center">
                    www.tipkit.co
                  </Col>
                </Row>
              </Card>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default ReceiptDownloadPage;
