import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { createValetTeam, getAllMaids } from "../redux/Actions/maidAction";
import { setTeamTipData } from "../redux/Actions/teamTipAction";
import axios from "axios";
import { BACKEND_URL } from "../config";
import { updateSesionTipAdded } from "../controllers/botSessions";

const TeamTip = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [validated, setValidated] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const [selected, setSelected] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [tipAmount, setTipAmount] = useState();
  const [cahrgedFees, setChargedFees] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [review, setReview] = useState("");
  const [amountError, setAmountError] = useState(false);

  const paymentState = useSelector((state) => state.payMaid);
  const { loading, success, error: stateError } = paymentState;
  const login = useSelector((state) => state.userLogin);
  const { userInfo } = login;
  const maidsList = useSelector((state) => state.maidsList);
  const { maids: allMaids } = maidsList;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hotelId = queryParams.get("hotel");
  const valetTeamLocation = queryParams.get("location");
  const sessionId = queryParams.get("sessionId");
  console.log("Session ID in team tip:", sessionId);
  let client;
  let merchantFee;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const calculateProcessingFee = async () => {
    const tipFees = tipAmount + 1;
    // --------------------------------------------------------------------------------------------
    // Updated processing fees (08/07/2024)
    // const processingFee = parseFloat((tipFees * 0.029 + 0.4).toFixed(2));
    // --------------------------------------------------------------------------------------------
    const processingFee = parseFloat((tipFees * 0.027 + 0.2).toFixed(2));
    merchantFee = parseFloat((1 + processingFee).toFixed(2));
    try {
      const { data } = await axios.get(
        `${BACKEND_URL}api/users/getById/${hotelId}`
      );
      client = data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    if (
      client &&
      client.partnerId != "" &&
      client.partnerId != "65b949bd84febb1899b71e0e"
    ) {
      merchantFee = parseFloat((merchantFee + 0.1).toFixed(2));
      console.log("Merchant fee with commision :", merchantFee);
    }
    const totalAmount = parseFloat((tipAmount + merchantFee).toFixed(2));
    setChargedFees(merchantFee);
    setTotalAmount(totalAmount);
    console.log("Total amount :", totalAmount);
  };

  const valets = allMaids.filter((maid) => maid.type == "valet");
  const teamMembers = valets.filter((valet) => {
    return valet.tips.some((tip) => {
      const tipDate = new Date(tip.date);
      return tipDate >= new Date(startDate) && tipDate <= new Date(endDate);
    });
  });
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setShowTip(true);
    }
    setValidated(true);
  };
  const handlePayment = () => {
    console.log("Team tip payment was initiated");
    dispatch(
      setTeamTipData({
        hotelId,
        valetTeamLocation,
        startDate,
        endDate,
        tipAmount,
        review,
        teamMembers,
      })
    );
    const teamTip = "true";
    const sessionInfo = {
      tip: tipAmount,
      review,
      teamTip,
      hotel: hotelId,
      selected,
      sessionId,
    };
    const sessionInfoString = JSON.stringify(sessionInfo);
    navigate(`/payment?sessionInfo=${sessionInfoString}`);
    // navigate(
    //   "/payment?amount=" +
    //     tipAmount +
    //     "&review=" +
    //     review +
    //     "&teamTip=" +
    //     true +
    //     "&hotel=" +
    //     hotelId
    // );
  };
  const handleTipAmount = () => {
    if (tipAmount === 0) {
      setAmountError(true);
      return;
    } else {
      setAmountError(false);
      setShowReview(true);
    }
  };
  const handleTipButtonClick = async (amount) => {
    setTipAmount(amount);
    setSelected(true);
    await updateSesionTipAdded(sessionId);
  };
  const handleReviewButtonClick = (text) => {
    setReview(text);
  };

  const handleCustomTipChange = async (event) => {
    setTipAmount(parseInt(event.target.value) || 0);
    setSelected(false);
    await updateSesionTipAdded(sessionId);
  };
  const handleCustomReviewChange = (event) => {
    setReview(event.target.value);
  };

  useEffect(() => {
    dispatch(getAllMaids(hotelId));
    // fetchData();
  }, [dispatch]);

  return (
    <>
      <Row className="h-100 w-75 m-auto justify-content-center align-items-center">
        <Col md={4} sm={4} className="">
          {showPayment ? (
            <>
              <h5 className="text-center text-success">Thank You!</h5>
              <hr />
              <Row className="justify-content-between">
                <Col xs="auto">Tip amount :</Col>
                <Col xs="auto">${tipAmount}</Col>
              </Row>
              <hr />
              <Row className="justify-content-between">
                <Col xs="auto">Processing fee :</Col>
                <Col xs="auto">${cahrgedFees}</Col>
              </Row>
              <hr />
              <Row className="justify-content-between">
                <Col xs="auto">Total amount </Col>
                <Col xs="auto">= ${totalAmount}</Col>
              </Row>
              <Row className="mt-4 px-5">
                <Button
                  onClick={handlePayment}
                  variant="outline-success"
                  className="rounded-4"
                >
                  Pay Now
                </Button>
              </Row>
            </>
          ) : showReview ? (
            <>
              <Row className="fs-6 fw-bold">
                Your review is also very important for us, select one or enter a
                custom review
              </Row>
              <Row className="justify-content-center mt-2">
                <Col xs="auto" className="m-1">
                  <Button
                    variant="outline-light"
                    className="px-4 py-2 text-dark rounded-4"
                    onClick={() => handleReviewButtonClick("Good Job!")}
                  >
                    Good job!
                  </Button>
                </Col>
                <Col xs="auto" className="m-1">
                  <Button
                    variant="outline-light"
                    className="px-4 py-2 text-dark rounded-4"
                    onClick={() => handleReviewButtonClick("Great Service!")}
                  >
                    Great service!
                  </Button>
                </Col>
                <Col xs="auto" className="mt-2 justify-content-center">
                  <Button
                    variant="outline-light"
                    className="px-4 py-2 text-dark rounded-4"
                    onClick={() => handleReviewButtonClick("Awesome work!")}
                  >
                    Awesome work!
                  </Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Form.Group controlId="formBasicTi[">
                  <Form.Label className="text-muted">Your Review</Form.Label>
                  <Form.Control
                    className="rounded-4 p-3"
                    type="text"
                    value={review}
                    onChange={handleCustomReviewChange}
                  />
                </Form.Group>
              </Row>
              <Row className="mt-3 justify-content-end">
                <Col xs="auto">
                  <Button
                    variant="outline-success"
                    className="px-5 rounded-4"
                    onClick={() => {
                      setShowPayment(true);
                      calculateProcessingFee();
                    }}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </>
          ) : showTip ? (
            <Row>
              <h5>
                Perfect! Please select your desired tip amount or select a
                custom tip amount
              </h5>
              {amountError && (
                <span className="text-danger">
                  *Please select or enter an amount
                </span>
              )}
              <Row className="justify-content-center mt-2">
                <Col xs={3} className="m-1">
                  <Button
                    variant="outline-light"
                    className="px-4 py-2 text-dark rounded-4"
                    onClick={() => handleTipButtonClick(5)}
                  >
                    $5
                  </Button>
                </Col>
                <Col xs={3} className="m-1">
                  <Button
                    variant="outline-light"
                    className="px-4 py-2 text-dark rounded-4"
                    onClick={() => handleTipButtonClick(10)}
                  >
                    $10
                  </Button>
                </Col>
                <Col xs={3} className="m-1">
                  <Button
                    variant="outline-light"
                    className="px-4 py-2 text-dark rounded-4"
                    onClick={() => handleTipButtonClick(15)}
                  >
                    $15
                  </Button>
                </Col>
              </Row>
              <Row className="mt-3">
                <Form.Group controlId="formBasicTi[">
                  <Form.Label className="text-muted">Custom Amount</Form.Label>
                  <Form.Control
                    className="rounded-4 p-3"
                    type="number"
                    placeholder="Custom Amount"
                    value={tipAmount}
                    onChange={handleCustomTipChange}
                  />
                </Form.Group>
                {tipAmount > 100 && (
                  <span className="text-danger fst-italic fs-7 ms-2">
                    Your amount exceeds the allowed limit
                  </span>
                )}
              </Row>
              <Row className="mt-3 justify-content-end">
                <Col xs="auto">
                  <Button
                    disabled={tipAmount > 100}
                    variant="outline-success"
                    className="px-5 rounded-4"
                    onClick={handleTipAmount}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Row>
          ) : (
            <>
              <h5>
                Let's make sure this tip goes to the right team,{" "}
                <b>Select a time frame</b>
              </h5>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                className="my-4"
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-bold">Start Date</Form.Label>
                  <Form.Control
                    required
                    className="rounded-4"
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    *Please choose a starting date.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-bold">End Date</Form.Label>
                  <Form.Control
                    required
                    className="rounded-4"
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    *Please choose a starting date.
                  </Form.Control.Feedback>
                </Form.Group>

                <Row className="">
                  <Col xs="auto" className="ms-auto">
                    <Button
                      className="px-4 rounded-5 mt-2"
                      variant="outline-success"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default TeamTip;
