import React, { useEffect, useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import {
  faBars,
  faChartColumn,
  faChevronDown,
  faCircleUser,
  faCubes,
  faHandshakeAngle,
  faMoneyBillTransfer,
  faPowerOff,
  faArrowLeft,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../images/tipkitLogo.svg";

import {
  Button,
  Col,
  Container,
  Dropdown,
  Navbar,
  Row,
  Tab,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetails,
  impersonateUserEnd,
  userLogout,
} from "../redux/Actions/userAction";
import { setActiveTab } from "../redux/Actions/tabAcions";
import AdminDashboard from "./AdminDashboard";
import AdminProfilePage from "./AdminProfilePage";
import BizDev from "./BizDev";
// import TransactionReports from "./TransactionReports";
import BizDevReports from "./BizDevRoperts";
import Stats from "./Stats";
import EditClientDetails from "./EditClientDetails";
import OverlayLoader from "../components/OverlayLoader";
import QrAnalytics from "./QrAnalytics";
const TransactionReports = lazy(() => import("./TransactionReports"));

const SuperAdmin = () => {
  const [props, setProps] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const isImpersonating = useSelector(
    (state) => state.userLogin.userInfo.userImpersonating
  );
  const activeTab = useSelector((state) => state.navigation.activeTab);
  const userInfo = useSelector((state) => state.userDetails);
  const { user } = userInfo;
  const [screenLoading, setScreenLoading] = useState(false);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(userLogout());
    window.location.href = "/login";
  };
  // const handleTabChange = (tabKey, propsValue = null) => {
  //   setProps(propsValue);
  //   dispatch(setActiveTab(tabKey));
  // };
  const handleTabChange = (tabKey, propsValue = null) => {
    if (activeTab !== tabKey) {
      setProps(propsValue);
      dispatch(setActiveTab(tabKey));
    }
  };

  const handleBackToSuperAdmin = () => {
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(!screenLoading);
      dispatch(
        impersonateUserEnd(isImpersonating.id, isImpersonating.email, navigate)
      );
    }, 1000);
  };

  useEffect(() => {
    if (!user) {
      dispatch(getUserDetails());
    }
  }, [dispatch]);

  return (
    <>
      {screenLoading ? <OverlayLoader /> : null}
      {isImpersonating && (
        <div className="py-1 bg-success">
          <Container
            fluid
            className="d-flex justify-content-between align-items-center"
          >
            {/* "Logged in as" label */}
            <span className="text-light fs-7">
              <strong> {isImpersonating.name}</strong> Logged in as: {user.name}
              <strong>
                {user && (user.isAdmin ? user.companyName : user.name)}
              </strong>
            </span>
            {/* "Back to Super Admin" button */}
            <Button
              variant="outline-success text-light border-0"
              size="sm"
              onClick={handleBackToSuperAdmin}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="me-2 text-light" />
              Back to {isImpersonating.name || "dashboard"}
            </Button>
          </Container>
        </div>
      )}
      <Navbar bg="primary" variant="dark" className="py-3">
        <Container fluid className="p-0">
          <Row className="justify-content-between align-items-center w-100">
            <Col xs="auto" className={`text-start px-4`}>
              <Button className="fs-5" onClick={() => setCollapsed(!collapsed)}>
                <FontAwesomeIcon icon={faBars} />
              </Button>
            </Col>
            <Col xs="auto" className="text-center">
              <Navbar.Brand>
                <img
                  src={Logo}
                  alt="tipkit"
                  width="100"
                  className="d-inline-block align-top"
                />
              </Navbar.Brand>
            </Col>
            <Col xs="auto">
              <Dropdown align={"end"}>
                <Dropdown.Toggle
                  variant="primary"
                  id="dropdown-basic"
                  className="custom-dropdown-toggle"
                >
                  <FontAwesomeIcon icon={faChevronDown} className="fs-5" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="px-2">
                  <Button
                    className="w-100 border-0"
                    variant="outline-danger"
                    onClick={handleLogout}
                  >
                    <FontAwesomeIcon icon={faPowerOff} className="me-2" />{" "}
                    Logout
                  </Button>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
      </Navbar>

      <Tab.Container activeKey={activeTab}>
        <Row className="m-0">
          <Col
            sm={collapsed ? 1 : 2}
            className={`sidebar vh-100 bg-primary sticky-top ${
              collapsed ? "collapsed" : "expanded"
            }`}
          >
            <Row className="h-100">
              <div className="pt-5 mt-5 w-100">
                <Button
                  variant={activeTab === "#dashboard" ? "light" : "primary"}
                  className={`w-100 text-start mb-2 ${
                    collapsed ? "text-center" : ""
                  }`}
                  onClick={() => handleTabChange("#dashboard")}
                >
                  <FontAwesomeIcon icon={faCubes} className="me-3" />
                  {!collapsed && "Dashboard"}
                </Button>

                {user && user.isSuperAdmin && (
                  <>
                    <Button
                      variant={
                        activeTab === "#devpartners" ? "light" : "primary"
                      }
                      className={`w-100 text-start mb-2 ${
                        collapsed ? "text-center" : ""
                      }`}
                      onClick={() => handleTabChange("#devpartners")}
                    >
                      <FontAwesomeIcon
                        icon={faHandshakeAngle}
                        className="me-3"
                      />
                      {!collapsed && "Partners"}
                    </Button>

                    <Button
                      variant={
                        activeTab === "#transactions" ? "light" : "primary"
                      }
                      className={`w-100 text-start mb-2 ${
                        collapsed ? "text-center" : ""
                      }`}
                      onClick={() => {
                        handleTabChange("#transactions");
                        setCollapsed(true);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMoneyBillTransfer}
                        className="me-3"
                      />
                      {!collapsed && "Reports"}
                    </Button>

                    <Button
                      variant={activeTab === "#stats" ? "light" : "primary"}
                      className={`w-100 text-start mb-2 ${
                        collapsed ? "text-center" : ""
                      }`}
                      onClick={() => {
                        handleTabChange("#stats");
                        setCollapsed(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faChartColumn} className="me-3" />
                      {!collapsed && "Stats"}
                    </Button>

                    <Button
                      variant={activeTab === "#qrStats" ? "light" : "primary"}
                      className={`w-100 text-start mb-2 ${
                        collapsed ? "text-center" : ""
                      }`}
                      onClick={() => handleTabChange("#qrStats")}
                    >
                      <FontAwesomeIcon icon={faChartPie} className="me-3" />
                      {!collapsed && "QR Analytics"}
                    </Button>
                  </>
                )}

                <Button
                  variant={activeTab === "#profilePage" ? "light" : "primary"}
                  className={`w-100 text-start mb-2 ${
                    collapsed ? "text-center" : ""
                  }`}
                  onClick={() => handleTabChange("#profilePage")}
                >
                  <FontAwesomeIcon icon={faCircleUser} className="me-3" />{" "}
                  {!collapsed && "Profile"}
                </Button>

                {user && user.isPartner && (
                  <Button
                    variant={activeTab === "#reports" ? "light" : "primary"}
                    className={`w-100 text-start mb-2 ${
                      collapsed ? "text-center" : ""
                    }`}
                    onClick={() => handleTabChange("#reports")}
                  >
                    <FontAwesomeIcon
                      icon={faMoneyBillTransfer}
                      className="me-3"
                    />
                    {!collapsed && "Reports"}
                  </Button>
                )}
              </div>
            </Row>
          </Col>
          <Col sm={collapsed ? 11 : 10} className="p-0 mx-auto">
            <Tab.Content>
              <Tab.Pane eventKey="#dashboard">
                <AdminDashboard
                  onTabChange={handleTabChange}
                  setCollapsed={setCollapsed}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="#profilePage">
                <AdminProfilePage />
              </Tab.Pane>
              <Tab.Pane eventKey="#devpartners">
                <BizDev />
              </Tab.Pane>
              {/* <Tab.Pane eventKey="#transactions">
                <TransactionReports />
              </Tab.Pane> */}
              <Tab.Pane eventKey="#transactions">
                {activeTab === "#transactions" && (
                  <Suspense fallback={<OverlayLoader />}>
                    <TransactionReports />
                  </Suspense>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="#stats">
                <Stats />
              </Tab.Pane>
              <Tab.Pane eventKey="#qrStats">
                <QrAnalytics />
              </Tab.Pane>
              {user && user.isPartner && (
                <Tab.Pane eventKey="#reports">
                  <BizDevReports partner={user} />
                </Tab.Pane>
              )}
              <Tab.Pane eventKey="#editClient">
                <EditClientDetails clientId={props} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default SuperAdmin;
