export const calculateMaidStats = (maid) => {
  const totalEarnings = maid.tips.reduce((acc, tip) => acc + tip.amount, 0);
  const totalReviews = maid.tips.reduce(
    (acc, tip) => acc + tip.review.length,
    0
  );
  return { totalEarnings, totalReviews };
};

export const formatMaidTips = (maids) => {
  const allTips = [];
  maids.forEach((maid) => {
    const { firstName, lastName, employId, serviceName, tips } = maid;
    tips.forEach((tip) => {
      allTips.push({
        ...tip,
        maidName: `${firstName} ${lastName}`,
        employId: employId,
        serviceName: serviceName,
      });
    });
  });

  return allTips;
};
