import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row } from "react-bootstrap";
import { getClientPayments } from "../redux/Actions/paymentAction";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CellBadge from "../components/CellBadge";

const ClientReports = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { userInfo } = useSelector((state) => state.userLogin);
  const hotelId = userInfo.isAdmin == true ? userInfo._id : userInfo.hotel;
  const {
    loading,
    payments: reports,
    totalPayments: totalReports,
    currentPage,
  } = useSelector((state) => state.clientPayments);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const dispatch = useDispatch();

  const processedReports = useMemo(
    () => reports.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
    [reports]
  );

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "rowNumber",
        size: 50,
        Cell: ({ row }) => (
          <span className="fw-semibold">{row.index + 1}.</span>
        ),
        enableGrouping: false,
      },
      {
        header: "Date",
        accessorKey: "createdAt",
        size: 120,
        Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString(),
      },
      {
        header: "Transaction ID",
        accessorKey: "paymentIntentId",
        size: 250,
        Cell: ({ cell }) => (
          <span className="text-muted fst-italic">{cell.getValue()}</span>
        ),
      },
      {
        header: "Tip Type",
        accessorKey: "tipType",
        size: 100,
        Cell: ({ cell }) =>
          cell.getValue() === "teamTip" ? "Pool Tip" : "Direct Tip",
      },
      {
        header: "Service",
        accessorKey: "service",
        size: 120,
        Cell: ({ cell }) =>
          cell.getValue() === "maid" ? (
            <CellBadge label={"Attendants"} />
          ) : cell.getValue() === "valet" ? (
            <CellBadge label={"Valet"} />
          ) : cell.getValue() === "bellman" ? (
            <CellBadge label={"Bell Staff"} />
          ) : null,
      },
      {
        header: "Tip Amount",
        accessorKey: "tipAmount",
        size: 100,
        Cell: ({ cell }) => (
          <Box sx={{ color: "success.main" }}>${cell.getValue()}</Box>
        ),
      },
      {
        header: "Total Amount",
        accessorKey: "amount",
        size: 100,
        Cell: ({ cell }) => (
          <Box sx={{ color: "success.main" }}>${cell.getValue()}</Box>
        ),
      },
      {
        header: "Recipient ID",
        accessorKey: "connectAccount",
        size: 190,
        Cell: ({ cell }) => (
          <span className="text-muted fst-italic">{cell.getValue()}</span>
        ),
      },
      {
        header: "Time",
        accessorKey: "time",
        size: 100,
        Cell: ({ cell }) =>
          new Date(cell.getValue()).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    enableColumnOrdering: true,
    data: reports,
    state: {
      pagination,
      isLoading: loading,
    },
    onPaginationChange: setPagination,
    rowCount: totalReports,
    manualPagination: true,
    enablePagination: true,
    enableColumnResizing: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableHiding: true,
    initialState: {
      density: "comfortable",
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: 700,
        overflowX: "auto",
        overflowY: "auto",
        padding: 2,
        marginBottom: 2,
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": { width: "12px" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888",
          borderRadius: "18px",
        },
        "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#555" },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#f5f5f5",
        color: "#333",
        padding: "10px",
        textAlign: "center",
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box>
        {/* <span className="text-muted fs-7 me-2 ms-4">From</span>
        <TextField
          type="date"
          variant="standard"
          onChange={(e) => setStartDate(e.target.value)}
          sx={{ width: 110 }}
        />
        <span className="text-muted fs-7 mx-2">to</span>
        <TextField
          type="date"
          variant="standard"
          onChange={(e) => setEndDate(e.target.value)}
          sx={{ width: 110 }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(
              getClientReports(1, pagination.pageSize, startDate, endDate)
            );
          }}
        >
          Filter
        </Button> */}
        <Button
          onClick={() => {
            const csvRows = [
              columns.map((col) => col.header).join(","),
              ...processedReports.map((row) =>
                columns
                  .map((col) => JSON.stringify(row[col.accessorKey] || ""))
                  .join(",")
              ),
            ];
            const csvContent = csvRows.join("\n");
            const blob = new Blob([csvContent], {
              type: "text/csv;charset=utf-8;",
            });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "client-reports.csv";
            link.click();
          }}
          variant="outline-secondary"
          className="ms-3 border-0 fs-7"
        >
          <FontAwesomeIcon className="me-1" icon={faDownload} />
          Export CSV
        </Button>
      </Box>
    ),
  });

  useEffect(() => {
    dispatch(
      getClientPayments(hotelId, pagination.pageIndex + 1, pagination.pageSize)
    );
  }, [pagination.pageIndex, pagination.pageSize]);

  return (
    <Row className="mt-5 p-3">
      <MaterialReactTable table={table} />
    </Row>
  );
};

export default ClientReports;
