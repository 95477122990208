import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { Button, Form } from "react-bootstrap";

const FileInputField = ({ uploaded, onChange, accept }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    onChange(e, "forBellmen");
  };

  return (
    <div>
      <div>
        <input
          type="file"
          accept={accept}
          onChange={handleFileChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <Button
          className="border-0 px-2 p-1"
          onClick={handleButtonClick}
          variant={uploaded ? "outline-success" : "outline-primary"}
        >
          <FontAwesomeIcon icon={faCloudArrowUp} className="me-2" />
          {uploaded ? "Update" : "Upload"}
        </Button>
      </div>
    </div>
  );
};

export default FileInputField;
