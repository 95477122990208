import React, { useState, useEffect } from "react";
import { Button, Col, ListGroup, Modal, Row, Table } from "react-bootstrap";
import { Alert, DatePicker, Input } from "antd";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { useSelector } from "react-redux";
import { calculateMaidStats } from "../../controllers/reports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  faMagnifyingGlass,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
const { RangePicker } = DatePicker;

const SummarizedReportsb2b = ({ toDownload, isAdmin, maids, type }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [serviceName, setServiceName] = useState("");
  const [filteredMaidListData, setFilteredMaidListData] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedMaid, setSelectedMaid] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const options = { year: "numeric", month: "numeric", day: "numeric" };

  const maidEarnings = useSelector((state) => state.maidEarnings);
  const {
    loading: filterdLoading,
    earnings,
    error: filteredError,
  } = maidEarnings;

  const requiredMaidEarnings = earnings.filter((maid) => maid.type == type);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates || [null, null]);
    if (!dates || !dates[0] || !dates[1]) {
      maids.forEach((maid) => {
        maid.totalTipsInRange = null;
      });
    }
  };
  const handleServiceNameFilter = (e) => {
    setServiceName(e.target.value);
  };
  const handleFilter = () => {
    const filteredList = maids.filter((maid) => {
      if (
        serviceName &&
        !maid.serviceName.toLowerCase().includes(serviceName.toLowerCase())
      ) {
        return false;
      }
      if (dateRange && dateRange[0] && dateRange[1]) {
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);
        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
          return false;
        }
        const tipsInRange = maid.tips.filter((tip) => {
          const tipDate = new Date(tip.date);
          return tipDate >= startDate && tipDate <= endDate;
        });
        if (tipsInRange.length === 0) {
          return false;
        }
        maid.totalTipsInRange = tipsInRange.reduce(
          (total, tip) => total + tip.amount,
          0
        );
        maid.totalReviewsInRange = tipsInRange.length;
      }
      return true;
    });
    setFilteredMaidListData(filteredList);
    setNoResults(filteredList.length === 0);
  };
  const handleDownloadExcel = () => {
    const dataToExport =
      filteredMaidListData.length > 0 &&
      (serviceName || (dateRange && dateRange[0] && dateRange[1]))
        ? filteredMaidListData
        : maids;

    const data = dataToExport.map((maid) => ({
      "Employ ID": maid.employId,
      Name: `${maid.firstName} ${maid.lastName}`,
      "Service Name": maid.serviceName,
      "Total Earnings":
        maid.totalTipsInRange || calculateMaidStats(maid).totalEarnings,
      "Total Reviews":
        maid.totalReviewsInRange || calculateMaidStats(maid).totalReviews,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Summarized Reports");

    XLSX.writeFile(workbook, "summarized-reports.xlsx");
  };

  const handleModal = (maid) => {
    setSelectedMaid(maid);
    setShowModal(!showModal);
  };

  return (
    <>
      <Modal
        backdrop="static"
        centered
        show={showModal}
        onHide={handleModal}
        size="lg"
      >
        <Modal.Header className="bg-primary text-light">
          <Row className="w-100">
            <Col md="auto" className="ms-auto p-0">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={() => setShowModal(false)}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className="report">
          {selectedMaid && (
            <>
              <h5>{selectedMaid.firstName}'s Earning</h5>
              {startDate != "" && endDate != "" ? (
                <>
                  <span className="text-muted">From</span>
                  <span className="text-success mx-2">{startDate}</span>
                  <span className="text-muted">to</span>
                  <span className="text-success mx-2">{endDate}</span>
                </>
              ) : (
                <></>
              )}

              <ListGroup
                variant="flush"
                className=" p-3 my-3 rounded-1 border border-1"
              >
                <ListGroup.Item className="">
                  <Row className="justify-content-between">
                    <Col md={2} className="text-success">
                      Date
                    </Col>
                    <Col md={4} className="text-success">
                      Transaction Id
                    </Col>
                    <Col md={1} className="text-success">
                      Tip
                    </Col>
                    <Col md={2} className="text-success">
                      Reviews
                    </Col>
                    <hr className="mb-0 mt-1 p-0" />
                  </Row>
                </ListGroup.Item>
                {selectedMaid.tips.map((tip, index) => (
                  <ListGroup.Item className="py-0 my-2" key={index}>
                    <Row className="justify-content-between">
                      <Col md={2}>
                        {new Date(tip.date).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </Col>
                      <Col md={4} className="text-muted px-0">
                        {tip.paymentId}
                      </Col>
                      <Col md={1}>${tip.amount}</Col>
                      <Col md={2}>{tip.review}</Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          {isAdmin || toDownload ? (
            <>
              <Button
                onClick={() => handleDownload(selectedMaid)}
                variant="success"
              >
                <FontAwesomeIcon className="pe-2" icon={faDownload} />
                {downloading ? "downloading.." : "Download"}
              </Button>
            </>
          ) : (
            <></>
          )}
        </Modal.Footer> */}
      </Modal>
      <Row className="px-3">
        <Col>
          {noResults && (
            <Alert
              message="No results found"
              type="error"
              showIcon
              closable
              onClose={() => setNoResults(false)}
            />
          )}
          {/* --------------------------------------------------- FILTERS ---------------------------------------------- */}
          <Row className="my-4 align-items-center justify-content-center">
            <Col xs="auto" className="">
              <RangePicker
                className="py-2"
                value={dateRange}
                onChange={handleDateRangeChange}
                placeholder={["Start Date", "End Date"]}
              />
            </Col>
            <Col xs={4} className="my-auto">
              <Input
                className="py-2"
                placeholder="Service Name"
                value={serviceName}
                onChange={handleServiceNameFilter}
                allowClear
              />
            </Col>
            <Col xs="auto">
              <Button
                variant="outline-secondary"
                onClick={handleFilter}
                className="px-3 mx-1"
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Button>
              <Button
                variant="outline-secondary"
                onClick={handleDownloadExcel}
                className="px-3 mx-1"
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </Col>
          </Row>
          {/* ------------------------------------------------ Table ---------------------------------------  */}
          <Row>
            <Col className="p-3 border border-1 rounded-4">
              <Row>
                <Col md={2} className="text-primary fw-semibold fs-5">
                  ID
                </Col>
                <Col md={2} className="text-primary fw-semibold fs-5">
                  Name
                </Col>
                <Col md={2} className="text-primary fw-semibold fs-5">
                  @
                </Col>
                <Col
                  md={2}
                  className="text-primary text-center fw-semibold fs-5"
                >
                  Earnings
                </Col>
                <Col
                  md={2}
                  className="text-primary text-center fw-semibold fs-5"
                >
                  Reviews
                </Col>
              </Row>
              <hr className="my-3" />

              {filterdLoading ? (
                <Loader />
              ) : filteredError ? (
                <Message>{"Message"}</Message>
              ) : (
                <>
                  {(filteredMaidListData.length > 0 && serviceName && dateRange
                    ? filteredMaidListData
                    : maids
                  ).map((maid, index) => (
                    <Row className="align-items-center" key={index}>
                      <Col md={2} className="text-muted">
                        {maid.employId}
                      </Col>
                      <Col md={2}>{maid.firstName + " " + maid.lastName}</Col>
                      <Col md={2} className="text-success">
                        {maid.serviceName}
                      </Col>
                      <Col md={2} className="text-center">
                        $
                        {maid.totalTipsInRange ||
                          calculateMaidStats(maid).totalEarnings}
                      </Col>
                      <Col md={2} className="text-center">
                        {maid.totalReviewsInRange ||
                          calculateMaidStats(maid).totalReviews}
                      </Col>
                      <Col md="auto" className="ms-auto me-5">
                        <Button
                          className="p-1 px-2 border-0"
                          variant="outline-success"
                          onClick={() => handleModal(maid)}
                        >
                          <FontAwesomeIcon
                            icon={faClipboard}
                            className="me-2"
                          />
                          View
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SummarizedReportsb2b;
