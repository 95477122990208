import React, { useState, useEffect } from "react";
import { formatMaidTips } from "../controllers/reports";
import { Col, Row, Button } from "react-bootstrap";
import { Alert, DatePicker, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faDownload,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
const { RangePicker } = DatePicker;

const DetailedReports = ({ toDownload, isAdmin, maids, type }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [paymentId, setPaymentId] = useState("");
  const [stripeAccount, setStripeAccount] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [filteredMaidListData, setFilteredMaidListData] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const itemsPerPage = 15;
  const allTips = formatMaidTips(maids).sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const totalPages = Math.ceil(
    (filteredMaidListData.length > 0 ? filteredMaidListData : allTips).length /
      itemsPerPage
  );
  const currentItems = (
    filteredMaidListData.length > 0 ? filteredMaidListData : allTips
  ).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleFilter = () => {
    const filteredList = allTips.filter((tip) => {
      if (
        serviceName &&
        !tip.serviceName.toLowerCase().includes(serviceName.toLowerCase())
      ) {
        return false;
      }
      if (
        paymentId &&
        !tip.paymentId.toLowerCase().includes(paymentId.toLowerCase().trim())
      ) {
        return false;
      }
      if (
        stripeAccount &&
        !tip.account.toLowerCase().includes(stripeAccount.toLowerCase())
      ) {
        return false;
      }
      if (dateRange && dateRange[0] && dateRange[1]) {
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);
        const tipDate = new Date(tip.date);
        if (tipDate < startDate || tipDate > endDate) {
          return false;
        }
      }
      return true;
    });

    setFilteredMaidListData(filteredList);
    setNoResults(filteredList.length === 0);
    setCurrentPage(1);
  };
  const handleDownloadExcel = () => {
    const dataToExport =
      filteredMaidListData.length > 0 &&
      (paymentId ||
        serviceName ||
        stripeAccount ||
        (dateRange && dateRange[0] && dateRange[1]))
        ? filteredMaidListData
        : allTips;

    const data = dataToExport.map((transaction) => ({
      "Employ ID": transaction.employId,
      "Payment ID": transaction.paymentId,
      Date: new Date(transaction.date).toLocaleDateString("en-US"),
      Name: transaction.maidName,
      "Service Name": transaction.serviceName,
      Tip: `$${transaction.amount}`,
      "Stripe Account": transaction.account,
      Review: transaction.review[0],
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Detailed Report");

    XLSX.writeFile(workbook, "detailed-report.xlsx");
  };

  const handleDateRangeChange = (dates) => setDateRange(dates);
  const handlePaymentIdFilter = (e) => setPaymentId(e.target.value);
  const handleStripeAccountFilter = (e) => setStripeAccount(e.target.value);
  const handleServiceNameFilter = (e) => setServiceName(e.target.value);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [serviceName, paymentId, stripeAccount, dateRange]);

  return (
    <>
      <Row className="px-3 mb-5">
        {noResults && allTips?.length > 0 && (
          <Alert
            message="No results found"
            type="error"
            showIcon
            closable
            onClose={() => setNoResults(false)}
          />
        )}
        <Row className="my-4 align-items-center justify-content-center">
          <Col xs="auto">
            <RangePicker
              className="py-2"
              value={dateRange}
              onChange={handleDateRangeChange}
              placeholder={["Start Date", "End Date"]}
            />
          </Col>
          <Col xs={3} className="my-auto">
            <Input
              className="py-2"
              placeholder="Payment ID"
              value={paymentId}
              onChange={handlePaymentIdFilter}
              allowClear
            />
          </Col>
          <Col xs={2} className="my-auto">
            <Input
              className="py-2"
              placeholder="Stripe Account"
              value={stripeAccount}
              onChange={handleStripeAccountFilter}
              allowClear
            />
          </Col>
          <Col xs={2} className="my-auto">
            <Input
              className="py-2"
              placeholder="Service Name"
              value={serviceName}
              onChange={handleServiceNameFilter}
              allowClear
            />
          </Col>
          <Col xs="auto">
            <Button variant="outline-secondary" className="px-3 mx-1">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Button>
            <Button
              variant="outline-secondary"
              className="px-3 mx-1"
              onClick={handleDownloadExcel}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </Col>
        </Row>
        <Col className="p-3 border border-1 rounded-4 overflow-auto custom-scrollbar">
          <div className="d-flex flex-column">
            <Row className="flex-nowrap px-2">
              <Col md="auto" className="text-primary fw-semibold fs-5 pe-3">
                #
              </Col>
              <Col md={1} className="text-primary fw-semibold fs-5">
                Date
              </Col>
              <Col md={1} className="text-primary fw-semibold fs-5">
                ID
              </Col>
              <Col md={2} className="text-primary text-center fw-semibold fs-5">
                Name
              </Col>
              <Col md={1} className="text-primary fw-semibold fs-5">
                @
              </Col>
              <Col
                style={{ width: "80px" }}
                className="text-primary text-center fw-semibold fs-5 p-0"
              >
                Tip
              </Col>
              <Col md={2} className="text-primary text-center fw-semibold fs-5">
                Review
              </Col>
              <Col md={3} className="text-primary fw-semibold fs-5">
                Payment ID
              </Col>
              <Col md={2} className="text-primary text-center fw-semibold fs-5">
                Stripe Account
              </Col>
            </Row>
            <hr className="my-3" />

            {allTips.length > 0 ? (
              <>
                {currentItems.map((tip, index) => (
                  <React.Fragment key={index}>
                    <Row className="align-items-center flex-nowrap">
                      <Col
                        md={1}
                        style={{ width: "50px" }}
                        className="fw-semibold"
                      >
                        {index + 1}.
                      </Col>
                      <Col md={1} className="text-muted p-0">
                        {new Date(tip.date).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </Col>
                      <Col md={1} className="text-muted">
                        {tip.employId}
                      </Col>
                      <Col md={2} className="p-0 text-center">
                        {tip.maidName}
                      </Col>
                      <Col md={1} className="text-success p-0">
                        {tip.serviceName}
                      </Col>
                      <Col
                        className="text-center p-0"
                        style={{ width: "80px" }}
                      >
                        ${tip.amount}
                      </Col>
                      <Col md={2} className="text-center p-0">
                        {tip.review}
                      </Col>
                      <Col md={3} className="p-0 text-muted fst-italic">
                        {tip.paymentId}
                      </Col>
                      <Col md={2} className="text-center text-muted fst-italic">
                        {tip.account}
                      </Col>
                    </Row>
                    <hr className="my-1" />
                  </React.Fragment>
                ))}
              </>
            ) : (
              <>No records found.</>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center mt-5">
            <Button
              className="p-2"
              variant="outline-secondary"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <span className="mx-3">
              Page {currentPage} of {totalPages}
            </span>
            <Button
              className="p-2"
              variant="outline-secondary"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DetailedReports;
