import React, { useState } from "react";
import { Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import BellmanList from "./BellmanList";
import Reports from "./Reports";
import { faClipboard, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BellMan = ({ toAddMaid, toDeleteMaid, toDownload, isAdmin }) => {
  const [activeScreen, setActiveScreen] = useState("bellman");
  return (
    <>
      <Row className="my-4 justify-content-center">
        <Col xs={4}>
          <Button
            className="py-2 px-3 rounded-4 w-100"
            variant={
              activeScreen === "bellman" ? "primary" : "outline-secondary"
            }
            onClick={() => setActiveScreen("bellman")}
          >
            <FontAwesomeIcon icon={faUsers} className="me-2" />
            Bell Staff
          </Button>
        </Col>
        <Col xs={4}>
          <Button
            className="py-2 px-3 rounded-4 w-100"
            variant={
              activeScreen === "reports" ? "primary" : "outline-secondary"
            }
            onClick={() => setActiveScreen("reports")}
          >
            <FontAwesomeIcon icon={faClipboard} className="me-2" />
            Reports
          </Button>
        </Col>
      </Row>
      <Row className="">
        <Col>
          {activeScreen === "bellman" && (
            <BellmanList toAddMaid={toAddMaid} toDeleteMaid={toDeleteMaid} />
          )}
          {activeScreen === "reports" && (
            <Reports
              toDownload={toDownload}
              isAdmin={isAdmin}
              type={"bellman"}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default BellMan;
