import {
  faArrowTurnDown,
  faCircleDollarToSlot,
  faDownload,
  faMagnifyingGlass,
  faMoneyBillTrendUp,
  faRotateLeft,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { format, parse } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Row,
  Table,
  Form,
  FloatingLabel,
  Button,
  Modal,
} from "react-bootstrap";
import Loader from "../components/Loader";
import * as XLSX from "xlsx";
import CommissionMonthlyReport from "../components/CommissionMonthlyReport";

const BizDevRoperts = ({ partner }) => {
  const payments = partner && partner.commisionEarned;
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState({
    date: "",
    transactionId: "",
    clientName: "",
  });
  const [earningsFilter, setEarningsFilter] = useState({
    startDate: "",
    endDate: "",
    clientName: "",
  });
  const [earnings, setEarnings] = useState({});
  const [filteredEarnings, setFilteredEarnings] = useState({});

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return !isNaN(date.getTime())
      ? date.toLocaleDateString("en-US", options)
      : "Invalid Date";
  };
  const calculateTotalEarnings = (partner) => {
    if (!partner || !partner.commisionEarned) return 0;
    const total = partner.commisionEarned.reduce(
      (total, commission) => total + commission.amount,
      0
    );
    return parseFloat(total.toFixed(2));
  };
  const handleFilterChange = (fieldName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: value,
    }));
  };
  const filterPayments = () => {
    const payments = partner.commisionEarned;

    const filteredResults = payments.filter((payment) => {
      const dateMatch =
        !filters.date ||
        format(new Date(payment.timestamp), "yyyy-MM-dd") ===
          format(new Date(filters.date), "yyyy-MM-dd");

      const transactionIdMatch =
        !filters.transactionId ||
        payment.paymentId.includes(filters.transactionId.replace(/\s/g, ""));

      const clientNameMatch =
        !filters.clientName ||
        (payment.clientName &&
          payment.clientName
            .toLowerCase()
            .replace(/\s/g, "")
            .includes(filters.clientName.toLowerCase().replace(/\s/g, "")));

      return dateMatch && transactionIdMatch && clientNameMatch;
    });
    if (filteredResults.length < 1) {
      alert("No data found");
    }
    setFilteredPayments(filteredResults);
  };
  const clearFilters = () => {
    setFilters({
      date: "",
      transactionId: "",
      clientName: "",
    });
    setFilteredPayments([]);
  };
  const handleEraningsFilterChange = (fieldName, value) => {
    setEarningsFilter((prevFilters) => ({
      ...prevFilters,
      [fieldName]: value,
    }));
  };
  const filterEarnings = () => {
    let dataFound = false;
    const filteredEarnings = {};
    payments.forEach((payment) => {
      const clientName = payment.clientName;
      const timestampDate = new Date(payment.timestamp);
      if (
        (!earningsFilter.startDate ||
          timestampDate >= new Date(earningsFilter.startDate + "T00:00:00")) &&
        (!earningsFilter.endDate ||
          timestampDate <= new Date(earningsFilter.endDate + "T23:59:59")) &&
        (!earningsFilter.clientName ||
          clientName
            .toLowerCase()
            .includes(earningsFilter.clientName.toLowerCase().trim()))
      ) {
        dataFound = true;
        filteredEarnings[clientName] = filteredEarnings[clientName] || {
          totalEarnings: 0,
        };
        filteredEarnings[clientName].totalEarnings += parseFloat(
          payment.amount
        );
      }
    });
    if (!dataFound) {
      alert("No data found for the entered client name.");
    }
    return filteredEarnings;
  };

  const applyEarningsFilter = () => {
    if (earningsFilter.startDate || earningsFilter.endDate) {
      const filteredData = filterEarnings();
      if (filteredData && Object.keys(filteredData).length > 0) {
        setFilteredEarnings(filteredData);
      } else {
        alert("No data found");
      }
    } else {
      alert("Please enter both start date and end date");
    }
  };
  const clearEarningsFilters = () => {
    setEarningsFilter({
      startDate: "",
      endDate: "",
      clientName: "",
    });
    setFilteredEarnings({});
  };
  const handleDownload = (className) => {
    const table = className && document.querySelector(`.${className}`);
    const data = [];
    const headers = [];
    table.querySelectorAll("thead th").forEach((th) => {
      if (th.textContent.trim() !== "Receipt") {
        headers.push(th.textContent.trim());
      }
    });
    data.push(headers);
    table.querySelectorAll("tbody tr").forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell, index) => {
        rowData.push(cell.textContent.trim());
      });
      data.push(rowData);
    });
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `tipkit-${className}.xlsx`);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (partner && partner.commisionEarned.length > 0) {
      const totalEarningsByClient = partner.commisionEarned.reduce(
        (acc, commission) => {
          // Check if the timestamp is a valid date
          const timestampDate = new Date(commission.timestamp);
          if (!isNaN(timestampDate.getTime())) {
            const clientName = commission.clientName;
            acc[clientName] = acc[clientName] || {
              totalEarnings: 0,
              startDate: timestampDate,
              endDate: timestampDate,
            };
            acc[clientName].totalEarnings += parseFloat(
              commission.amount.toFixed(2)
            );
            acc[clientName].startDate = new Date(
              Math.min(acc[clientName].startDate, timestampDate)
            );
            acc[clientName].endDate = new Date(
              Math.max(acc[clientName].endDate, timestampDate)
            );
          }
          return acc;
        },
        {}
      );
      Object.keys(totalEarningsByClient).forEach((clientName) => {
        totalEarningsByClient[clientName].totalEarnings = parseFloat(
          totalEarningsByClient[clientName].totalEarnings.toFixed(2)
        );
      });
      setEarnings(totalEarningsByClient);
    } else {
      setEarnings({});
    }
  }, []);

  const styles = {
    shadow: {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header className="bg-primary text-light">
          <Row className="w-100">
            <Col md="auto" className="ms-auto p-0">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={handleClose}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className="p-4">
          <CommissionMonthlyReport partnerId={partner._id} />
        </Modal.Body>
      </Modal>
      <Row className="m-0 pt-5 p-3">
        <Col>
          <Row className="mb-3">
            <Col xs="auto" className="text-success ms-auto me-4">
              <FontAwesomeIcon
                className="fs-4 hover-effect"
                icon={faCircleDollarToSlot}
                onClick={() => {
                  setShow(true);
                }}
              />{" "}
              = <span>${partner && calculateTotalEarnings(partner)}</span>
            </Col>
          </Row>
          {/* --------------------------------------- REPORTS PER  CLIENT ------------------------------------------------ */}
          <Row className="rounded-3 mb-5 p-3" style={styles.shadow}>
            <Col>
              <h4 className="text-center text-primary fw-bold mb-4">
                Reports Per Client
              </h4>
              {/* ------------------------------------------ FILTERS ------------------------------------------------------ */}
              <Row className=" my-4 align-items-center justify-content-center">
                <Col xs={2}>
                  <FloatingLabel label="Start date">
                    <Form.Control
                      type="date"
                      placeholder="Date"
                      value={earningsFilter.startDate}
                      onChange={(e) =>
                        handleEraningsFilterChange("startDate", e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={2}>
                  <FloatingLabel label="End date">
                    <Form.Control
                      type="date"
                      placeholder="Date"
                      value={earningsFilter.endDate}
                      onChange={(e) =>
                        handleEraningsFilterChange("endDate", e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={3}>
                  <FloatingLabel label="Client Name">
                    <Form.Control
                      type="text"
                      placeholder="Client Name"
                      value={earningsFilter.clientName}
                      onChange={(e) =>
                        handleEraningsFilterChange("clientName", e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col xs="auto" className="">
                  <Button variant="secondary" onClick={clearEarningsFilters}>
                    <FontAwesomeIcon icon={faRotateLeft} />
                  </Button>
                  <Button
                    variant="success"
                    className="mx-2"
                    onClick={() => handleDownload("report-per-client")}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <Button
                    className="py-2 px-3"
                    variant="primary"
                    onClick={applyEarningsFilter}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="me-1"
                    />
                    Apply Filter
                  </Button>
                </Col>
              </Row>
              {/* ------------------------------------------ LIST ------------------------------------------------------ */}
              <Row className="my-2 p-2">
                <Table striped bordered hover className="report-per-client">
                  <thead>
                    <tr>
                      <th>Date Range</th>
                      <th>Client Name</th>
                      <th>Total Earnings</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(filteredEarnings).length > 0
                      ? Object.keys(filteredEarnings).map(
                          (clientName, index) => (
                            <tr key={index}>
                              <td>
                                {console.log(
                                  "Start date : ",
                                  earningsFilter.startDate
                                )}
                                <span className="fw-bold me-1">
                                  {earningsFilter.startDate}
                                </span>
                                to
                                <span className="fw-bold ms-1">
                                  {earningsFilter.endDate}
                                </span>
                              </td>
                              <td>{clientName}</td>
                              <td>
                                $
                                {parseFloat(
                                  filteredEarnings[
                                    clientName
                                  ].totalEarnings.toFixed(2)
                                )}
                              </td>
                            </tr>
                          )
                        )
                      : Object.keys(earnings).map((clientName, index) => (
                          <tr key={index}>
                            <td>
                              <span className="fw-bold me-1">
                                {earnings[
                                  clientName
                                ].startDate.toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </span>
                              to
                              <span className="fw-bold ms-1">
                                {earnings[
                                  clientName
                                ].endDate.toLocaleDateString("en-US", options)}
                              </span>
                            </td>
                            <td>{clientName}</td>
                            <td>
                              $
                              {parseFloat(
                                earnings[clientName].totalEarnings.toFixed(2)
                              )}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row>
          {/* --------------------------------------- OVERALL TRANSACTIONS ------------------------------------------------ */}
          <Row className="rounded-3 my-5 p-3" style={styles.shadow}>
            <Col>
              <h4 className="text-center text-primary fw-bold p-2">
                Transactions
              </h4>
              {/* ------------------------------------------ FILTERS ------------------------------------------------------ */}
              <Row className=" my-4 align-items-center justify-content-center">
                <Col xs={2}>
                  <FloatingLabel label="Date">
                    <Form.Control
                      type="date"
                      placeholder="Date"
                      value={filters.date}
                      onChange={(e) =>
                        handleFilterChange("date", e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={3}>
                  <FloatingLabel label="Transaction Id">
                    <Form.Control
                      type="text"
                      placeholder="Transaction Id"
                      value={filters.transactionId}
                      onChange={(e) =>
                        handleFilterChange("transactionId", e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={2}>
                  <FloatingLabel label="Client Name">
                    <Form.Control
                      type="text"
                      placeholder="Client Name"
                      value={filters.clientName}
                      onChange={(e) =>
                        handleFilterChange("clientName", e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col xs="auto" className="">
                  <Button variant="secondary" onClick={clearFilters}>
                    <FontAwesomeIcon icon={faRotateLeft} />
                  </Button>
                  <Button
                    variant="success"
                    className="mx-2"
                    onClick={() => handleDownload("overall-transactions")}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <Button
                    className="py-2 px-3"
                    variant="primary"
                    onClick={() => filterPayments()}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="me-1"
                    />
                    Apply Filter
                  </Button>
                </Col>
              </Row>
              <Row className="my-3 p-2">
                <Table striped hover bordered className="overall-transactions">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Transaction Id</th>
                      <th>Connect Account</th>
                      <th>Client Name</th>
                      <th>Tip</th>
                      <th>Merchant Fee</th>
                      <th>Commission</th>
                    </tr>
                  </thead>
                  <tbody>
                    {partner &&
                      payments.length > 0 &&
                      (filteredPayments.length > 0
                        ? filteredPayments
                        : payments
                      )
                        .slice()
                        .sort(
                          (a, b) =>
                            new Date(b.timestamp) - new Date(a.timestamp)
                        )
                        .map((commision, index) => (
                          <tr key={index}>
                            <td className="fw-bold">{index + 1}</td>
                            <td>{formatDate(commision.timestamp)}</td>
                            <td className="text-muted">
                              {commision.paymentId}
                            </td>
                            <td className="text-muted">
                              {commision.connectAccount}
                            </td>
                            <td>{commision.clientName}</td>
                            <td>${commision.tipAmount}</td>
                            <td>${commision.processingFee}</td>
                            <td className="text-success">
                              ${commision.amount}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BizDevRoperts;
