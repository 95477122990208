export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_IMPERSONATE_REQUEST = "USER_IMPERSONATE_REQUEST";
export const USER_IMPERSONATE_SUCCESS = "USER_IMPERSONATE_SUCCESS";
export const USER_IMPERSONATE_FAIL = "USER_IMPERSONATE_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const GET_SINGLE_USER_REQUEST = "GET_SINGLE_USER_REQUEST";
export const GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS";
export const GET_SINGLE_USER_FAIL = "GET_SINGLE_USER_FAIL";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";

export const ADD_ROLE_REQUEST = "ADD_ROLE_REQUEST";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAILURE = "ADD_ROLE_FAILURE";
export const ADD_ROLE_RESET = "ADD_ROLE_RESET";

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";

export const HOTEL_UPDATE_REQUEST = "HOTEL_UPDATE_REQUEST";
export const HOTEL_UPDATE_SUCCESS = "HOTEL_UPDATE_SUCCESS";
export const HOTEL_UPDATE_FAIL = "HOTEL_UPDATE_FAIL";
export const HOTEL_UPDATE_RESET = "HOTEL_UPDATE_RESET";

export const ROLE_USER_GET_ALL_REQUEST = "ROLE_USER_GET_ALL_REQUEST";
export const ROLE_USER_GET_ALL_SUCCESS = "ROLE_USER_GET_ALL_SUCCESS";
export const ROLE_USER_GET_ALL_FAIL = "ROLE_USER_GET_ALL_FAIL";

export const ROLE_USER_DELETE_REQUEST = "ROLE_USER_DELETE_REQUEST";
export const ROLE_USER_DELETE_SUCCESS = "ROLE_USER_DELETE_SUCCESS";
export const ROLE_USER_DELETE_FAIL = "ROLE_USER_DELETE_FAIL";

export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";
export const UPDATE_ROLE_RESET = "UPDATE_ROLE_RESET";

export const ROLE_USER_UPDATE_REQUEST = "ROLE_USER_UPDATE_REQUEST";
export const ROLE_USER_UPDATE_SUCCESS = "ROLE_USER_UPDATE_SUCCESS";
export const ROLE_USER_UPDATE_FAIL = "ROLE_USER_UPDATE_FAIL";
export const ROLE_USER_UPDATE_RESET = "ROLE_USER_UPDATE_RESET";

export const SHIFT_MONTHLY_COMMISSION_REQUEST =
  "SHIFT_MONTHLY_COMMISSION_REQUEST";
export const SHIFT_MONTHLY_COMMISSION_SUCCESS =
  "SHIFT_MONTHLY_COMMISSION_SUCCESS";
export const SHIFT_MONTHLY_COMMISSION_FAILURE =
  "SHIFT_MONTHLY_COMMISSION_FAILURE";

export const MONTHLY_COMMISSION_REQUEST = "MONTHLY_COMMISSION_REQUEST";
export const MONTHLY_COMMISSION_SUCCESS = "MONTHLY_COMMISSION_SUCCESS";
export const MONTHLY_COMMISSION_FAIL = "MONTHLY_COMMISSION_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
