import { SET_ACTIVE_TAB } from "../Constants/tabConstants";

const initialState = {
  activeTab: "#dashboard",
};
export const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      if (state.activeTab === action.payload) return state;
      return { ...state, activeTab: action.payload };
    default:
      return state;
  }
};
