import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Row, Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllMaids, getMaidEarnings } from "../redux/Actions/maidAction";
import { Link, useNavigate } from "react-router-dom";
import SummarizedReports from "./SummarizedReports";
import DetailedReports from "./DetailedReports";
import DetailedReportsb2b from "./B2b/DetailedReportsb2b";
import SummarizedReportsb2b from "./B2b/SummarizedReportsb2b";

const Reports = ({ toDownload, isAdmin, type }) => {
  const [selectedTab, setSelectedTab] = useState("summarizedReports");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedMaid, setSelectedMaid] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const maidsList = useSelector((state) => state.maidsList);
  const login = useSelector((state) => state.userLogin);
  const maidEarnings = useSelector((state) => state.maidEarnings);
  const {
    loading: filterdLoading,
    earnings,
    error: filteredError,
  } = maidEarnings;
  const { userInfo } = login;
  const { loading, error, maids: allMaids } = maidsList;

  const requiredMaids = allMaids.filter((maid) => maid.type == type);
  const requiredMaidEarnings = earnings.filter((maid) => maid.type == type);
  const hotelId = userInfo.isAdmin == true ? userInfo._id : userInfo.hotel;
  const model = userInfo?.model;
  // console.log("Model in reports: ", model);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleModal = (maid) => {
    setSelectedMaid(maid);
    setShowModal(!showModal);
  };
  const handleDownload = async (selectedMaid) => {
    localStorage.setItem("selectedMaid", JSON.stringify(selectedMaid));
    localStorage.setItem("startDate", startDate);
    localStorage.setItem("endDate", endDate);
    const url = `/download`;
    window.open(url, "_blank");
  };
  const handleReportDownload = async (maids) => {
    localStorage.setItem("maids", JSON.stringify(maids));
    const url = `/download`;
    window.open(url, "_blank");
  };
  const handleFilter = () => {
    if (startDate !== "" && endDate !== "") {
      setFilterApplied(true);
      dispatch(getMaidEarnings(hotelId, startDate, endDate));
      // setFilterApplied(true);
    } else {
      alert("Please enter both start date and end date");
    }
  };
  const clearFilters = () => {
    setStartDate("");
    setEndDate("");
    setFilterApplied(false);
    // setFilteredPayments(null);
  };

  useEffect(() => {
    dispatch(getAllMaids(hotelId));
  }, [dispatch, filterApplied]);
  const styles = {
    shadow: {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
  };

  const handleTabChange = (tab) => {
    if (loading) return;
    setSelectedTab(tab);
  };
  return (
    <>
      <Row className="justify-content-center">
        <Col md="auto" className="d-flex align-items-center">
          <span className="fs-3 text-primary">
            {selectedTab === "summarizedReports"
              ? "Summarized Reports"
              : "Detailed Reports"}
          </span>
          <Dropdown align={"end"}>
            <Dropdown.Toggle
              variant="outline-primary"
              id="dropdown-basic"
              className="custom-dropdown-toggle border-0 p-1 mx-3"
            >
              <FontAwesomeIcon icon={faChevronDown} className="fs-6" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="px-2" style={{ width: "300px" }}>
              <Button
                className="w-100 border-0 mb-2"
                variant="outline-success"
                onClick={() => handleTabChange("summarizedReports")}
              >
                Summarized Reports
              </Button>
              <Button
                className="w-100 border-0"
                variant="outline-success"
                onClick={() => handleTabChange("detailedReports")}
              >
                Detailed Reports
              </Button>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row className="">
        {model.b2b === true ? (
          <Col>
            {selectedTab === "summarizedReports" && (
              <SummarizedReportsb2b
                toDownload={toDownload}
                isAdmin={isAdmin}
                maids={requiredMaids}
                type={type}
              />
            )}
            {selectedTab === "detailedReports" && (
              <DetailedReportsb2b
                toDownload={toDownload}
                isAdmin={isAdmin}
                maids={requiredMaids}
                type={type}
              />
            )}
          </Col>
        ) : (
          <Col>
            {selectedTab === "summarizedReports" && (
              <SummarizedReports
                toDownload={toDownload}
                isAdmin={isAdmin}
                maids={requiredMaids}
                type={type}
              />
            )}
            {selectedTab === "detailedReports" && (
              <DetailedReports
                toDownload={toDownload}
                isAdmin={isAdmin}
                maids={requiredMaids}
                type={type}
              />
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default Reports;
