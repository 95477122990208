import axios from "axios";
import { BACKEND_URL } from "../config";

export const updateSesionTipAdded = async (sessionId) => {
  try {
    const response = await axios.patch(
      `${BACKEND_URL}api/chatbot-sessions/${sessionId}`
    );
    if (response.status === 200) {
      console.log("Session updated successfully:", response.data);
      return response.data;
    } else {
      console.error("Unexpected response status:", response.status);
    }
  } catch (error) {
    console.error(
      "Error updating the session:",
      error.response?.data || error.message
    );
  }
};
