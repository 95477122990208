import {
  faCheck,
  faPenToSquare,
  faPlus,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addRole,
  deleteRole,
  getRoles,
  getUserDetails,
  updateRole,
} from "../redux/Actions/userAction";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  ADD_ROLE_RESET,
  UPDATE_ROLE_RESET,
} from "../redux/Constants/userconstants";

const AddRoles = () => {
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [roleErrorMessage, setRoleErrorMessage] = useState("");
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [roleToEdit, setRoleToEdit] = useState(null);
  const [roleData, setRoleData] = useState({
    title: "",
    permissions: {
      read: false,
      download: false,
      addMaid: false,
      deleteMaid: false,
      viewAttendants: false,
      viewValets: false,
      viewBellmans: false,
    },
  });
  const { loading, error, roles, deleteSuccess } = useSelector(
    (state) => state.getRoles
  );
  const {
    loading: addLoading,
    error: addError,
    success,
  } = useSelector((state) => state.addRole);
  const { success: updateSuccess } = useSelector((state) => state.updateRole);
  const { user } = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();

  // --------------------------------------------------PERMISSIONS --------------------------------------------------
  let adminServiceMaids = false;
  let adminServiceValets = false;
  let adminServiceBellmen = false;
  let accountStatus = false;

  if (user.isAdmin == true) {
    if (user.accountStatus == true) {
      accountStatus = true;
    }
    if (user.services.maids == true) {
      adminServiceMaids = true;
    }
    if (user.services.valets == true) {
      adminServiceValets = true;
    }
    if (user.services.bellmen == true) {
      adminServiceBellmen = true;
    }
  }

  // -----------------------------------------------------------------------------------------------------------------
  const handleDeleteModal = () => setDeleteModal(false);

  const handlePermissionChange = (permission) => {
    setRoleData((prevData) => ({
      ...prevData,
      permissions: {
        ...prevData.permissions,
        [permission]: !prevData.permissions[permission],
      },
    }));
  };
  const handleEditShow = (roleId) => {
    setValidated(false);
    const role = roles.find((role) => role._id === roleId);
    if (role) {
      setRoleToEdit(role);
      setRoleData({
        title: role.title,
        permissions: { ...role.permissions },
      });
      setEditShow(true);
    }
  };
  const handleEditClose = () => {
    setEditShow(false);
    setValidated(false);
    setRoleErrorMessage("");
    setRoleData({
      title: "",
      permissions: {
        read: false,
        download: false,
        addMaid: false,
        deleteMaid: false,
        viewAttendants: false,
        viewValets: false,
        viewBellmans: false,
      },
    });
    dispatch({ type: UPDATE_ROLE_RESET });
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const isAtLeastOneCheckboxChecked = Object.values(
      roleData.permissions
    ).some((value) => value === true);
    const isDashboardPermissionSelected =
      roleData.permissions.viewAttendants ||
      roleData.permissions.viewValets ||
      roleData.permissions.viewBellmans;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else if (!isAtLeastOneCheckboxChecked) {
      e.preventDefault();
      e.stopPropagation();
      setRoleErrorMessage("Please select at least one permission");
    } else if (
      roleData.permissions.read &&
      !roleData.permissions.viewAttendants &&
      !roleData.permissions.viewValets &&
      !roleData.permissions.viewBellmans
    ) {
      e.preventDefault();
      e.stopPropagation();
      setRoleErrorMessage(
        "Please select at least one dashboard when 'Read only access' is selected"
      );
    } else if (isAtLeastOneCheckboxChecked && !isDashboardPermissionSelected) {
      e.stopPropagation();
      setRoleErrorMessage("Please select at least one dashboard permission");
    } else {
      dispatch(updateRole(roleToEdit._id, roleData));
      handleEditClose();
    }

    setValidated(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const isAtLeastOneCheckboxChecked = Object.values(
      roleData.permissions
    ).some((value) => value === true);
    const isDashboardPermissionSelected =
      roleData.permissions.viewAttendants ||
      roleData.permissions.viewValets ||
      roleData.permissions.viewBellmans;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else if (!isAtLeastOneCheckboxChecked) {
      e.preventDefault();
      e.stopPropagation();
      setRoleErrorMessage("Please select at least one permission");
    } else if (
      roleData.permissions.read &&
      !roleData.permissions.viewAttendants &&
      !roleData.permissions.viewValets &&
      !roleData.permissions.viewBellmans
    ) {
      e.preventDefault();
      e.stopPropagation();
      setRoleErrorMessage(
        "Please select at least one dashboard when 'Read only access' is selected"
      );
    } else if (isAtLeastOneCheckboxChecked && !isDashboardPermissionSelected) {
      e.stopPropagation();
      setRoleErrorMessage("Please select at least one dashboard permission");
    } else {
      dispatch(addRole(roleData));
    }

    setValidated(true);
  };
  const handleDelete = (roleId) => {
    if (roleToDelete) {
      dispatch(deleteRole(roleToDelete));
      setRoleToDelete(null);
      handleDeleteModal();
    }
  };
  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setRoleErrorMessage("");
  };

  const handleShow = () => {
    setRoleData({
      title: "",
      permissions: {
        read: false,
        download: false,
        addMaid: false,
        deleteMaid: false,
        viewAttendants: false,
        viewValets: false,
        viewBellmans: false,
      },
    });
    setShow(true);
    setValidated(false);
    dispatch({ type: ADD_ROLE_RESET });
  };
  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getRoles());
  }, [dispatch, success, deleteSuccess, updateSuccess]);
  const styles = {
    shadow: {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
  };

  return (
    <>
      {/* ------------------------------------------ EDIT ROLE MODAL -------------------------------------------- */}
      <Modal
        show={editShow}
        onHide={handleEditClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="bg-primary text-light">
          <Row className="w-100">
            <Col xs="auto" className="fs-4">
              Edit Role
            </Col>
            <Col md="auto" className="ms-auto p-0">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={handleEditClose}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleEditSubmit}>
            <Form.Group className="mb-3" controlId="editForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Edit the role title"
                value={roleData.title}
                onChange={(e) =>
                  setRoleData({ ...roleData, title: e.target.value })
                }
              />
              <Form.Control.Feedback type="invalid">
                This field is required*
              </Form.Control.Feedback>
            </Form.Group>
            <h6 className="">Edit permissions</h6>
            <Form.Group>
              <Form.Check
                className="my-3"
                type="checkbox"
                id="editRead"
                label="Read only access"
                checked={roleData.permissions.read}
                onChange={() => handlePermissionChange("read")}
              />
              {adminServiceMaids ? (
                <Form.Check
                  className="my-3"
                  type="checkbox"
                  id="editViewAttendants"
                  label="Access attendants dashboard"
                  checked={roleData.permissions.viewAttendants}
                  onChange={() => handlePermissionChange("viewAttendants")}
                />
              ) : null}
              {adminServiceValets ? (
                <Form.Check
                  className="my-3"
                  type="checkbox"
                  id="editViewValets"
                  label="Access valet dashboard"
                  checked={roleData.permissions.viewValets}
                  onChange={() => handlePermissionChange("viewValets")}
                />
              ) : null}
              {adminServiceBellmen ? (
                <Form.Check
                  className="my-3"
                  type="checkbox"
                  id="editViewBellmans"
                  label="Access bell desk dashboard"
                  checked={roleData.permissions.viewBellmans}
                  onChange={() => handlePermissionChange("viewBellmans")}
                />
              ) : null}
              <Form.Check
                className="my-3"
                type="checkbox"
                id="editDownload"
                label="Can download reports"
                checked={roleData.permissions.download}
                onChange={() => handlePermissionChange("download")}
              />
              <Form.Check
                className="my-3"
                type="checkbox"
                id="editAddMaid"
                label="Can add new members"
                checked={roleData.permissions.addMaid}
                onChange={() => handlePermissionChange("addMaid")}
              />
              <Form.Check
                className="my-3"
                type="checkbox"
                id="editDeleteMaid"
                label="Can delete members"
                checked={roleData.permissions.deleteMaid}
                onChange={() => handlePermissionChange("deleteMaid")}
              />
              <Form.Text className="text-danger">{roleErrorMessage}</Form.Text>
            </Form.Group>
            <Row className=" mt-4 mb-3">
              <Col xs="auto" className="ms-auto">
                <Button variant="secondary" onClick={handleEditClose}>
                  Cancel
                </Button>
              </Col>
              <Col xs="auto">
                <Button variant="success" type="submit">
                  Save Changes
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {/* ------------------------------------------ CREATE ROLE -------------------------------------------- */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="bg-primary text-light">
          <Row className="w-100">
            <Col xs="auto" className="fs-4">
              Create New Role
            </Col>
            <Col md="auto" className="ms-auto p-0">
              <Button
                variant="outline-light p-0 px-1  m-0 border-0"
                onClick={handleClose}
              >
                <FontAwesomeIcon className="fs-4" icon={faXmark} />
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          {addLoading ? (
            <Loader className="my-5" />
          ) : addError ? (
            <Message className="my-5">{addError}</Message>
          ) : success ? (
            <>
              <div className="text-center text-success p-3 ">
                Role created successfully !
              </div>
              <div className="d-none">
                {setTimeout(() => {
                  handleClose();
                }, 2000)}
              </div>
            </>
          ) : (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Give this role a title"
                  value={roleData.title}
                  onChange={(e) =>
                    setRoleData({ ...roleData, title: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  This field is required*
                </Form.Control.Feedback>
              </Form.Group>
              <h6 className="">Give permissions</h6>
              <Form.Group>
                <Form.Check
                  className="my-3"
                  type="checkbox"
                  id="read"
                  label="Read only access"
                  checked={roleData.permissions.read}
                  onChange={() => handlePermissionChange("read")}
                />
                {adminServiceMaids ? (
                  <Form.Check
                    className="my-3"
                    type="checkbox"
                    id="viewAttendants"
                    label="Access attendants dashboard"
                    checked={roleData.permissions.viewAttendants}
                    onChange={() => handlePermissionChange("viewAttendants")}
                  />
                ) : null}
                {adminServiceValets ? (
                  <Form.Check
                    className="my-3"
                    type="checkbox"
                    id="viewValets"
                    label="Access valet dashboard"
                    checked={roleData.permissions.viewValets}
                    onChange={() => handlePermissionChange("viewValets")}
                  />
                ) : null}
                {adminServiceBellmen ? (
                  <Form.Check
                    className="my-3"
                    type="checkbox"
                    id="viewBellmans"
                    label="Access bell desk dashboard"
                    checked={roleData.permissions.viewBellmans}
                    onChange={() => handlePermissionChange("viewBellmans")}
                  />
                ) : null}

                <Form.Check
                  className="my-3"
                  type="checkbox"
                  id="download"
                  label="Can download reports"
                  checked={roleData.permissions.download}
                  onChange={() => handlePermissionChange("download")}
                />
                <Form.Check
                  className="my-3"
                  type="checkbox"
                  id="addMaid"
                  label="Can add new members"
                  checked={roleData.permissions.addMaid}
                  onChange={() => handlePermissionChange("addMaid")}
                />
                <Form.Check
                  className="my-3"
                  type="checkbox"
                  id="deleteMaid"
                  label="Can delete members"
                  checked={roleData.permissions.deleteMaid}
                  onChange={() => handlePermissionChange("deleteMaid")}
                />
                <Form.Text className="text-danger">
                  {roleErrorMessage}
                </Form.Text>
              </Form.Group>
              <Row className="px-3 mt-4 mb-3">
                <Button type="submit">Save</Button>
              </Row>
            </Form>
          )}
        </Modal.Body>
      </Modal>
      {/* ------------------------------------------ DELETE MODAL ----------------------------------------- */}
      <Modal
        show={deleteModal}
        onHide={handleDeleteModal}
        backdrop="static"
        centered
      >
        <Modal.Body className="p-4 text-center">
          <b>Are you sure you want to delete this role?</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Row className="py-5 px-4">
        <Col>
          <Row className="mb-3">
            <Col sm="auto" className="ms-auto">
              <Button onClick={handleShow} className="rounded-5 px-3 py-2">
                <FontAwesomeIcon className="me-1" icon={faPlus} /> Create Role
              </Button>
            </Col>
          </Row>
          {/* -------------------------------------------- ROLES LIST ------------------------------------------- */}
          <Row className="py-4">
            <Col className="p-4 rounded-4" style={styles.shadow}>
              <Row>
                <Col md="auto">
                  <b className="text-primary ">#</b>
                </Col>
                <Col md={2} className="p-0 text-primary">
                  <b>Title</b>
                </Col>
                <Col md={8} className="p-0 text-center text-primary">
                  <b>Permissions</b>
                </Col>
                <Col md={1} className="p-0 text-primary ms-auto">
                  <b>Actions</b>
                </Col>
              </Row>
              <hr className="my-3" />
              {loading ? (
                <Loader />
              ) : error ? (
                <Message>{error}</Message>
              ) : (
                roles.map((role, index) => (
                  <>
                    <Row key={role._id}>
                      <Col md="auto">
                        <b> {index + 1}.</b>
                      </Col>
                      <Col md={2} className="p-0">
                        {role.title}
                      </Col>
                      <Col md={8} className="p-0">
                        {role.permissions.viewValets && (
                          <Badge className="mx-2 my-1" pill bg="success">
                            <FontAwesomeIcon className="me-1" icon={faCheck} />
                            Valet Dashboard Access
                          </Badge>
                        )}
                        {role.permissions.viewBellmans && (
                          <Badge className="mx-2 my-1" pill bg="success">
                            <FontAwesomeIcon className="me-1" icon={faCheck} />
                            Bellman Dashboard Access
                          </Badge>
                        )}
                        {role.permissions.viewAttendants && (
                          <Badge className="mx-2 my-1" pill bg="success">
                            <FontAwesomeIcon className="me-1" icon={faCheck} />
                            Attendants Dashboard Access
                          </Badge>
                        )}
                        {role.permissions.read && (
                          <Badge className="mx-2 my-1" pill bg="success">
                            <FontAwesomeIcon className="me-1" icon={faCheck} />
                            Read
                          </Badge>
                        )}
                        {role.permissions.download && (
                          <Badge className="mx-2 my-1" pill bg="success">
                            <FontAwesomeIcon className="me-1" icon={faCheck} />
                            Download
                          </Badge>
                        )}
                        {role.permissions.addMaid && (
                          <Badge className="mx-2 my-1" pill bg="success">
                            <FontAwesomeIcon className="me-1" icon={faCheck} />
                            Add Maid
                          </Badge>
                        )}
                        {role.permissions.deleteMaid && (
                          <Badge className="mx-2 my-1" pill bg="success">
                            <FontAwesomeIcon className="me-1" icon={faCheck} />
                            Delete Maid
                          </Badge>
                        )}
                      </Col>
                      <Col md={1} className="p-0 pe-1 ms-auto">
                        <Button
                          className="me-2"
                          onClick={() => handleEditShow(role._id)}
                          variant="outline-primary"
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                        <Button
                          onClick={() => {
                            setRoleToDelete(role._id);
                            setDeleteModal(true);
                          }}
                          variant="outline-danger"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </Col>
                    </Row>
                    <hr className="my-2" />
                  </>
                ))
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AddRoles;
