import {
  ADD_ROLE_FAILURE,
  ADD_ROLE_REQUEST,
  ADD_ROLE_RESET,
  ADD_ROLE_SUCCESS,
  ADD_USER_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  DELETE_ROLE_SUCCESS,
  GET_ROLES_FAIL,
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  HOTEL_UPDATE_FAIL,
  HOTEL_UPDATE_REQUEST,
  HOTEL_UPDATE_RESET,
  HOTEL_UPDATE_SUCCESS,
  MONTHLY_COMMISSION_FAIL,
  MONTHLY_COMMISSION_REQUEST,
  MONTHLY_COMMISSION_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  ROLE_USER_DELETE_FAIL,
  ROLE_USER_DELETE_SUCCESS,
  ROLE_USER_GET_ALL_FAIL,
  ROLE_USER_GET_ALL_REQUEST,
  ROLE_USER_GET_ALL_SUCCESS,
  ROLE_USER_UPDATE_FAIL,
  ROLE_USER_UPDATE_REQUEST,
  ROLE_USER_UPDATE_RESET,
  ROLE_USER_UPDATE_SUCCESS,
  SHIFT_MONTHLY_COMMISSION_FAILURE,
  SHIFT_MONTHLY_COMMISSION_REQUEST,
  SHIFT_MONTHLY_COMMISSION_SUCCESS,
  UPDATE_ROLE_FAIL,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_RESET,
  UPDATE_ROLE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_RESET,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_IMPERSONATE_REQUEST,
  USER_IMPERSONATE_SUCCESS,
  USER_IMPERSONATE_FAIL,
  GET_SINGLE_USER_REQUEST,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAIL,
} from "../Constants/userconstants";

export const userLoginReducer = (state = { userInfo: null }, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return { userInfo: null };
    case USER_IMPERSONATE_REQUEST:
      return { ...state, loading: true };
    case USER_IMPERSONATE_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_IMPERSONATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userRegisterReducer = (state = { userInfo: null }, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, success: true };
    case USER_REGISTER_FAIL:
      return { loading: false, success: false, error: action.payload };
    case USER_REGISTER_RESET:
      return { success: false };
    case USER_LOGOUT:
      return { userInfo: null };
    default:
      return state;
  }
};

export const monthlyCommissionReducer = (
  state = { monthlyCommission: [], loading: false, error: "" },
  action
) => {
  switch (action.type) {
    case MONTHLY_COMMISSION_REQUEST:
      return { ...state, loading: true };
    case MONTHLY_COMMISSION_SUCCESS:
      return { loading: false, monthlyCommission: action.payload, error: "" };
    case MONTHLY_COMMISSION_FAIL:
      return { loading: false, monthlyCommission: [], error: action.payload };
    default:
      return state;
  }
};
export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_REGISTER_SUCCESS:
      return { loading: false, success: true };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { user: {} };
    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true, users: [] };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload, users: [] };
    case USER_DELETE_SUCCESS:
      return {
        users: state.users.filter((p) => p._id !== action.payload._id),
        success: true,
      };
    case USER_DELETE_FAIL:
      return { error: action.payload };
    default:
      return state;
  }
};
export const singleUserReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case GET_SINGLE_USER_REQUEST:
      return { loading: true, ...state };
    case GET_SINGLE_USER_SUCCESS:
      return { loading: false, user: action.payload };
    case GET_SINGLE_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const getRolesReducer = (state = { roles: [] }, action) => {
  switch (action.type) {
    case GET_ROLES_REQUEST:
      return { loading: true, roles: [] };
    case GET_ROLES_SUCCESS:
      return { loading: false, roles: action.payload };
    case GET_ROLES_FAIL:
      return { loading: false, error: action.payload, roles: [] };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        roles: state.roles.filter((role) => role._id !== action.payload._id),
      };
    default:
      return state;
  }
};

export const roleReducer = (state = { role: {} }, action) => {
  switch (action.type) {
    case ADD_ROLE_REQUEST:
      return { ...state, loading: true };
    case ADD_ROLE_SUCCESS:
      return { loading: false, success: true, role: action.payload };
    case ADD_ROLE_FAILURE:
      return { loading: false, error: action.payload };
    case ADD_ROLE_RESET:
      return { success: false };

    default:
      return state;
  }
};

export const addUserReducer = (state = { newUser: null }, action) => {
  switch (action.type) {
    case ADD_USER_REQUEST:
      return { loading: true };
    case ADD_USER_SUCCESS:
      return { loading: false, success: true, newUser: action.payload };
    case ADD_USER_FAILURE:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const getRoleUsersReducer = (state = { roleUsers: [] }, action) => {
  switch (action.type) {
    case ROLE_USER_GET_ALL_REQUEST:
      return { loading: true, roleUsers: [] };
    case ROLE_USER_GET_ALL_SUCCESS:
      return { loading: false, roleUsers: action.payload };
    case ROLE_USER_GET_ALL_FAIL:
      return { loading: false, error: action.payload, roleUsers: [] };
    case ROLE_USER_DELETE_SUCCESS:
      return {
        roleUsers: state.roleUsers.filter((p) => p._id !== action.payload._id),
        success: true,
      };
    case ROLE_USER_DELETE_FAIL:
      return { error: action.payload };
    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const hotelUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case HOTEL_UPDATE_REQUEST:
      return { loading: true };
    case HOTEL_UPDATE_SUCCESS:
      return { loading: false, success: true, hotel: action.payload };
    case HOTEL_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case HOTEL_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const updateRoleReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ROLE_REQUEST:
      return { loading: true };
    case UPDATE_ROLE_SUCCESS:
      return { loading: false, success: true, role: action.payload };
    case UPDATE_ROLE_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_ROLE_RESET:
      return {};
    default:
      return state;
  }
};

export const roleUserUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLE_USER_UPDATE_REQUEST:
      return { loading: true };
    case ROLE_USER_UPDATE_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case ROLE_USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ROLE_USER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const shiftCommissionReducer = (state = {}, action) => {
  switch (action.type) {
    case SHIFT_MONTHLY_COMMISSION_REQUEST:
      return { loading: true };
    case SHIFT_MONTHLY_COMMISSION_SUCCESS:
      return { loading: false, shiftedSuccess: true };
    case SHIFT_MONTHLY_COMMISSION_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
