import {
  faDownLong,
  faDownload,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { Alert, Button, Card, Col, Image, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addRating } from "../redux/Actions/maidAction";
import Receipt from "./Receipt";

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9",
};
const Rating = ({ maid, transactionData }) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [ratingSuccess, setRatingSuccess] = useState(false);

  const stars = Array(5).fill(0);

  const dispatch = useDispatch();

  const handleClick = (value) => {
    setCurrentValue(value);
  };
  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };
  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };
  const handleSubmit = () => {
    if (currentValue != 0) {
      if (maid) {
        dispatch(
          addRating(maid, currentValue, transactionData.paymentIntentId)
        );
      }
      setRatingSuccess(true);
    }
  };

  const styles = {
    shadow: {
      boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
  };
  return (
    <>
      {ratingSuccess ? (
        <>
          <Alert variant="success" className="text-center">
            Thank you for your courtesy!
          </Alert>
          <Receipt transactionData={transactionData} />
        </>
      ) : (
        <Row
          style={styles.shadow}
          className="justify-content-center border border-1 rounded-5 fs-5 pt-5 p-3"
        >
          <span className="text-center mb-5 text-primary">Leave a rating</span>
          <Col sm="auto" className="text-center" style={styles.stars}>
            {stars.map((_, index) => {
              return (
                <FontAwesomeIcon
                  icon={faStar}
                  key={index}
                  size="2x"
                  onClick={() => handleClick(index + 1)}
                  onMouseOver={() => handleMouseOver(index + 1)}
                  onMouseLeave={handleMouseLeave}
                  color={
                    (hoverValue || currentValue) > index
                      ? colors.orange
                      : colors.grey
                  }
                  style={{
                    marginRight: 5,
                    cursor: "pointer",
                  }}
                />
              );
            })}
          </Col>
          <Row className="mt-3">
            <span className="text-center fs-6 text-warning">
              {currentValue == 1
                ? "Bad"
                : currentValue == 2
                ? "Not bad"
                : currentValue == 3
                ? "Good job"
                : currentValue == 4
                ? "Awesome job!"
                : currentValue == 5
                ? "Loved the service!"
                : ""}
            </span>
          </Row>
          <Col sm={4}>
            <Row>
              <Button
                className="my-5"
                variant="outline-success"
                style={styles.button}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Rating;
