import React from "react";
import { Row } from "react-bootstrap";

const TermsConditions = () => {
  return (
    <>
      <Row className="p-5 m-0">
        <h4 className="text-center">Tipkit Terms of Service</h4>
        <p className="mt-2 mb-5 text-center">Last Updated: 12/13/2023</p>
        <h5>1. Introduction</h5>
        <p>
          Welcome to tipkit. These Terms of Service ("Terms") govern your access
          to and use of tipkit's web-based chatbot service ("Service") and any
          related services provided by tipkit ("we", "us", or "our").
        </p>
        <h5>2. Acceptance of Terms</h5>
        <p>
          By using tipkit, you agree to be bound by these Terms. If you do not
          agree with any part of these Terms, you should not use our Service.
        </p>
        <h5>3. Description of the Service</h5>
        <p>
          Tipkit provides a platform for users to tip hotel staff via a
          web-based chatbot. The Service enables cashless tipping and integrates
          with payment providers like Dock and Stripe for transaction
          processing.
        </p>
        <h5>4. User Accounts and Security</h5>
        <p>
          While tipkit does not require account creation, you are responsible
          for any activity conducted through the Service. We encourage you to
          maintain the confidentiality of your payment information.
        </p>
        <h5>5. Privacy</h5>
        <p>
          Your use of tipkit is also governed by our Privacy Policy, which
          outlines how we collect, use, and share your information.
        </p>
        <h5>6. Use of the Service</h5>
        <p>
          You are granted a non-exclusive, non-transferable, limited right to
          access and use tipkit for personal, non-commercial purposes, subject
          to these Terms.
        </p>
        <h5>7. Prohibited Conduct</h5>
        <p>
          You may not use tipkit for any unlawful or unauthorized purpose,
          including but not limited to:
        </p>
        <ul>
          <li>Engaging in fraudulent activities.</li>
          <li>Violating the rights of others.</li>
          <li>Interfering with the operation of the Service.</li>
        </ul>
        <h5>8. Intellectual Property</h5>
        <p>
          All content, trademarks, logos, and service marks displayed on tipkit
          are our property or the property of other third parties. You are not
          permitted to use these without our prior written consent or the
          consent of such third party.
        </p>
        <h5>9. Third-Party Services</h5>
        <p>
          Tipkit integrates with third-party services like Dock and Stripe. Your
          interaction with these services is governed by their respective terms
          and privacy policies.
        </p>
        <h5>10. Limitation of Liability</h5>
        <p>
          We shall not be liable for any indirect, incidental, special,
          consequential, or punitive damages arising out of or in connection
          with your use of tipkit.
        </p>
        <h5>11. Indemnification</h5>
        <p>
          You agree to indemnify and hold harmless tipkit and its affiliates,
          officers, agents, and employees from any claim or demand made by any
          third party due to or arising out of your use of the Service
        </p>
        <h5>12. Modification of Terms</h5>
        <p>
          We reserve the right to modify these Terms at any time. Your continued
          use of tipkit after any such changes constitutes your acceptance of
          the new Terms.
        </p>
        <h5>13. Termination</h5>
        <p>
          We may terminate or suspend your access to tipkit at any time, without
          notice, for conduct that we believe violates these Terms or is harmful
          to other users of tipkit, or for any other reason.
        </p>
        <h5>14. Governing Law</h5>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of the jurisdiction in which we are based, without giving effect
          to any principles of conflicts of law.
        </p>
        <h5>15. Contact Information</h5>
        <p>
          If you have any questions about these Terms, please contact us at
          hello@tipkit.co
        </p>
      </Row>
    </>
  );
};

export default TermsConditions;
