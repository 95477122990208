import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BACKEND_URL, onStaging } from "../config";

const StagingProtection = ({ children }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    // Hardcoded credentials
    const correctUsername = "admin@stagging@tipkit";
    const correctPassword = "superadmin#1@tipkit";

    if (username === correctUsername && password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert("Invalid username or password");
    }
  };

  return (
    <div>
      {!isAuthenticated && onStaging ? (
        <Row className="justify-content-center align-items-center vh-100 m-0">
          <Col
            md={4}
            className="pt-5 rounded-3 border border-1 bg-white"
            //   style={styles.shadow}
          >
            <Row className="justify-content-center mb-5">
              <Col xs="auto" className="text-primary">
                <h3>Restricted Access</h3>
              </Col>
            </Row>
            <Row></Row>
            <Form className="px-5">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>

                <div className="position-relative">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    // style={{ paddingRight: "40px" }}
                  />
                  <span
                    className="position-absolute top-50 end-0 translate-middle-y me-2 text-muted"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                  </span>
                </div>
              </Form.Group>
              <Row className="justify-content-center px-5 my-5">
                <Button
                  className="py-2"
                  variant="outline-primary"
                  onClick={handleLogin}
                >
                  Login
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
};

export default StagingProtection;
